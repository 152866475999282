import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class PlatformFeatures extends React.Component {

  render() {
    return (
      <section className="PlatformFeatures">
        <Container>
          <Row className="py-md-5 justify-content-center text-center">
            <Col md={12} className="text-center mb-3 mb-md-5">
              <h1>Easy for you, great for the planet </h1>
            </Col>
            <Col xs={10} sm={10} md={6} lg={3} className="mb-3 mb-md-0">
              <div className="icon">
                <img src="images/Eco-Friendly.svg" alt="trees-icon"></img >
              </div>
              <div className="text">
                <h3 className="my-md-3 mb-0">Eco-friendly</h3>
                <p>Give the trees a break with this paperless alternative </p>
              </div>
            </Col>
            <Col xs={10} sm={10} md={6} lg={3} className="mb-3 mb-md-0">
              <div className="icon">
                <img src="images/personalise-icon.svg" alt="message-icon"></img>
              </div>
              <div className="text">
                <h3 className="my-md-3 mb-0">Keep it real</h3>
                <p>Add a unique message for that personal touch </p>
              </div>
            </Col>
            <Col xs={10} sm={10} md={6} lg={3} className="mb-3 mb-md-0">
              <div className="icon">
                <img src="images/Add-Gift-icon.svg" alt="gift-icon"></img>
              </div>
              <div className="text">
                <h3 className="my-md-3 mb-0">Double-up</h3>
                <p>Combine the classic ecard with a donation to charity </p>
              </div>
            </Col>
            <Col xs={10} sm={10} md={6} lg={3}>
              <div className="icon">
                <img src="images/Calendar-icon.svg" alt="calendar-icon"></img>
              </div>
              <div className="text">
                <h3 className="my-md-3 mb-0">Press send and sit back</h3>
                <p className="PlatformFeaturesText">Immediate delivery or scheduled – you decide!</p>
              </div>
            </Col>
            <Col md={12} className="d-flex justify-content-center my-5">
              <Link to="/occasions">
                <Button className="mb-2 btn">Send your own card</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

}

export default PlatformFeatures;
