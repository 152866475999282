// Thanks, Mark Thomas Miller
// https://zeph.co/react-router-scroll-to-top

import React from 'react';
import { withRouter } from 'react-router-dom';
import propTypes from 'prop-types';

class ScrollToTop extends React.Component {

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  static get propTypes() {
    return {
      location: propTypes.object,
      children: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node,
      ]),
    };
  }

  render() {
    return this.props.children || null;
  }

}

export default withRouter(ScrollToTop);
