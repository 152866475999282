import React from 'react';
import propTypes from 'prop-types';
import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import siteSettings from '../config/settings';

class SiteHeader extends React.Component {

  static get propTypes() {
    return {
      siteName: propTypes.string,
    };
  }

  render () {
    return (
      <header className="SiteHeader">
        <Container>
          <Row>
            <Col>
              <Navbar collapseOnSelect expand="md" variant="dark" sticky="top">
                <Navbar.Brand>
                  <Link to='/'>
                    <img
                      className="logo"
                      src={
                        siteSettings.endpoints.content +
                        siteSettings.theme.logoImage
                      }
                      title={ siteSettings.siteName }
                      alt={ siteSettings.siteName + ' logo' }
                    />
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="justify-content-center">
                    <Link className="nav-link" to="/occasions">
                      <Nav.Item>Occasions</Nav.Item>
                    </Link>
                    <Link className="nav-link" to="/how-it-works">
                      <Nav.Item>How it works</Nav.Item>
                    </Link>
                    <Link className="nav-link" to="/faq">
                      <Nav.Item>FAQs</Nav.Item>
                    </Link>
                  </Nav>
                </Navbar.Collapse>
                {/* <Form inline className="search">
                  <button className="search">
                    <svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                    </svg>
                  </button>
                </Form> */}
              </Navbar>

            </Col>
          </Row>
        </Container>
      </header>
    );
  }

}

export default SiteHeader;
