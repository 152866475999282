import React from 'react';
import propTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import Product from './Product';
import LoadingPlaceholder from './LoadingPlaceholder';
import Error from './Error';

import { getCategoryBySlug, getProductsByCategoryId, getTotalProductsByCategoryId } from '../utilities/api';

const productSetSize = 16;

class ProductList extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      products: [],
      status: 'loading',
      categoryId: props.categoryId,
      totalProducts: -1,
    };
    this.loadProducts = this.loadProducts.bind(this);
    this._isMounted = false;
  }

  static get propTypes() {
    return {
      categoryId: propTypes.string,
      categorySlug: propTypes.string,
      products: propTypes.array,
    };
  }

  loadProducts() {
    this.setState({ status: 'loading' });
    // Get next visible records by making an API call with skip set to
    // prev.visible, and adding the returned items to the products array
    getProductsByCategoryId(this.state.categoryId, productSetSize, this.state.products.length)
      .then( (value) => {
        this._isMounted && this.setState({
          products: this.state.products.concat(value),
          status: 'okay',
        });
      })
      .catch( (error) => {
        this._isMounted && this.setState({
          products: [],
          status: 'error',
        });
        console.error(error);
      } );
  }

  componentDidMount() {
    this._isMounted = true;

    // We blindly display any directly provided products
    // (No further processing.)
    if (this.props.products && this.props.products.length) {
      this.setState({
        products: this.props.products,
        status: 'okay',
      });
      return;
    }

    // Hopefully we have a category ID at this point
    // Otherwise we fetch it using the slug
    let categoryIdPromise;
    if (this.props.categoryId) {
      categoryIdPromise = Promise.resolve(this.props.categoryId);
    } else {
      categoryIdPromise = getCategoryBySlug(this.props.categorySlug)
        .then( (category) => {
          return category._id || category.id;
        });
    }

    // When we have an ID we populate our state and run the first load
    categoryIdPromise
      .then( (categoryId) => {
        this.setState({ categoryId: categoryId });
        getTotalProductsByCategoryId(categoryId)
          .then( (value) => {
            this.setState({ totalProducts: value });
            this.loadProducts();
          });
      })
      .catch( (error) => {
        this._isMounted && this.setState({
          totalProducts: 0,
          status: 'error',
        });
        console.error(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    if (this.state.status === 'error') {
      return <Error />;
    }

    if (this.state.products.length || this.state.status === 'loading') {
      return (
        <section className="ProductList">
          <Container>

            <Row>
              {this.state.products.map((product, index) => {
                return (
                  <Col xs={12} sm={6} lg={3} key={ index } className="mb-4 mb-md-0">
                    <Product product={ product } mode='thumbnail' thumbnailLink={true} animationOffset={ index % productSetSize }/>
                  </Col>
                );
              })}
            </Row>

            { this.state.status === 'loading' && <LoadingPlaceholder /> }

            { this.state.products.length < this.state.totalProducts &&
              this.state.status === 'okay' &&
              <Row className="justify-content-center">
                <Col xs={12} sm={4}>
                  <button onClick={this.loadProducts} type="button" className="btn btn-primary btn-block load-more">Load more</button>
                </Col>
              </Row>
            }

          </Container>
        </section>
      );
    }
    return (
      <section className="ProductList ProductList--empty">
        <Container>
          <Row>
            <Col>
              <p>No products found</p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

}

export default ProductList;
