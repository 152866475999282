import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import siteSettings from '../config/settings';

import Animation from './Animation';
class HomeBanner extends React.Component {

  render() {
    return (
      <section className="HomeBanner">
        <Container className="py-5">
          <Row className="justify-content-center align-items-center">

            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
              {this.props.decoration &&
                <img alt="" className="homepage-decoration-image" src={siteSettings.endpoints.content + this.props.decoration} />
              }
              <h1 className="my-4">An eco-friendly way to brighten someone’s day </h1>
              <p className="mb-4">Have an occasion to celebrate? It’s simpler than ever to send a paperless surprise to someone special. And you can help protect people and our planet while putting a smile on someone’s face.</p>
              <Row className="justify-content-center">
                <Col md={12} className="d-flex justify-content-center">
                  <Link to="/occasions">
                    <button className="mb-2 btn btn-primary">Browse all cards</button>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
              {/* Fake up product and gift details for the animation */}
              <Animation
                status="okay"
                config={{
                  interactive: false,
                  autoTrigger: false,
                  initialStateOverride: {
                    isEnvelopeActive: true,
                  },
                }}
                product={{
                  assets: {
                    image: this.props.cardImage || 'theme/home-page-card.gif',
                  },
                }}
                gift={{
                  recipient: {
                    name: 'To: Anyone',
                  },
                  message: 'Send a card for any occasion',
                }}
              />
            </Col>
          </Row>
        </Container>
      </section >
    );
  }

}

export default HomeBanner;
