import React from 'react';
import propTypes from 'prop-types';

import SiteHeader from './SiteHeader';
import SiteContent from './SiteContent';
import SiteFooter from './SiteFooter';

import siteSettings from '../config/settings';

class Page extends React.Component {

  static get propTypes() {
    return {
      children: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node,
      ]),
    };
  }

  render () {
    return <>
      <SiteHeader siteName={ siteSettings.siteName } />
      <SiteContent>
        { this.props.children }
      </SiteContent>
      <SiteFooter />
    </>;
  }

}

export default Page;
