import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';

import Input from '../Form/Input';
import Payment from '../Payment';
import Product from '../Product';

import { nameRegex, emailRegex } from '../../utilities/regex-patterns';

export default function GiftFormSenderDetails(props) {
  const [pristine, setPristine] = useState(true);

  // On first render check the starting values if we have some
  // hinthint: localstorage
  useEffect(() => {
    Object.keys(props.orderData).map((key) => {
      const item = props.orderData[key];
      if (item && item.length > 0) {
        setPristine(false);
      }
      return true;
    });
  }, []);

  const handleConsentRadioChange = (event) => {
    const value = (event.currentTarget.value === 'yes');
    props.updateOrderData({ [event.currentTarget.name]: value });
  };

  const consentEmailOptIn = () => {
    props.updateOrderData({ consentEmail: true });
  };

  const handleInputChange = (event) => {
    if (pristine === true) {
      setPristine(false);
    }
    if (event.target !== document.activeElement &&
      (event.target.type == 'text' || event.target.type == 'email') ) {
      event.target.value = event.target.value.trim();
    }
    props.handleOrderInputChange(event);
  };

  const giftData = Object.assign(
    {},
    props.giftData,
    {
      recipientName: props.giftData.recipients.length > 1 ?
        'Recipient name' :
        props.giftData.recipients.find(Boolean).recipientName,
      message: props.giftData.recipients.length > 1 ?
        'Individual messages\nwill appear here' :
        props.giftData.recipients.find(Boolean).message,
    },
  );

  return (<Row className="justify-content-center">
    <Col xs={12} md={5}>
      <Product
        mode="preview"
        previewMode="product"
        product={ props.product }
        // Note: I've chatted with Rebecca about this one
        // she suggested to keep the preview for the first recipient only.
        giftData={ giftData }
      />
    </Col>
    <Col xs={12} md={6}>

      <Form id="sender-details-form" noValidate onSubmit={props.saveAndNextStep}>
        <h2 className="mb-3 mb-lg-4">Your Details</h2>

        <Input
          autoFocus
          required
          controlId="senderFirstName"
          label="First name"
          type="text"
          pristine={pristine}
          defaultValue={props.orderData.senderFirstName}
          onChange={handleInputChange}
          validation={value => nameRegex.test(value)}
          invalidText="Please enter your first name. Symbols are not allowed."
        />

        <Input
          required
          controlId="senderLastName"
          label="Last name"
          type="text"
          pristine={pristine}
          defaultValue={props.orderData.senderLastName}
          onChange={handleInputChange}
          validation={value => nameRegex.test(value)}
          invalidText="Please enter your last name. Symbols are not allowed."
        />

        <Input
          required
          controlId="senderEmail"
          label="Your email"
          type="email"
          placeholder="address@example.com"
          pattern={emailRegex.toString().replace(/^\/|\/$/g, '')}
          pristine={pristine}
          defaultValue={props.orderData.senderEmail}
          onChange={handleInputChange}
          validation={value => emailRegex.test(value)}
          invalidText="Please enter your valid email address."
        />

        {/* Show the consent section if we DON'T have activeopt, or we DO have giftaid */}
        { (props.prefillData.activeopt !== 'yes' || props.orderData.giftAid ) && <>
          <h4 className="my-2">Let’s keep in touch</h4>
          <p className="small-text"><small>By signing up for communications you consent to us further contacting you about our campaigns and how you can get involved, including whether you can help with a donation.</small></p>

          { props.prefillData.activeopt !== 'yes' &&
          <>
            <h6 className="my-2">Contact by email?*</h6>
            <Form.Group controlId="consentEmail">
              <Form.Check inline>
                <Form.Check.Input
                  type="radio"
                  required
                  id="consentEmailYes"
                  name="consentEmail"
                  value="yes"
                  label="Yes"
                  checked={props.orderData.consentEmail === true}
                  onChange={handleConsentRadioChange}
                />
                <Form.Check.Label htmlFor="consentEmailYes">Yes</Form.Check.Label>
                <Form.Control.Feedback type="invalid">Needs a selection</Form.Control.Feedback>
              </Form.Check>
              <Form.Check inline>
                <Form.Check.Input
                  type="radio"
                  required
                  id="consentEmailNo"
                  name="consentEmail"
                  value="no"
                  label="No"
                  checked={props.orderData.consentEmail === false}
                  onChange={handleConsentRadioChange}
                />
                <Form.Check.Label htmlFor="consentEmailNo">No</Form.Check.Label>
                <Form.Control.Feedback type="invalid">Needs a selection</Form.Control.Feedback>
              </Form.Check>
            </Form.Group>
          </>
          }

          { props.orderData.giftAid &&
          <>
            <h6 className="my-2">Contact by post?</h6>
            <Form.Group controlId="consentPost">
              <Form.Check inline>
                <Form.Check.Input
                  type="radio"
                  id="consentPostYes"
                  name="consentPost"
                  value="yes"
                  label="Yes"
                  checked={props.orderData.consentPost === true}
                  onChange={handleConsentRadioChange}
                />
                <Form.Check.Label htmlFor="consentPostYes">Yes</Form.Check.Label>
                <Form.Control.Feedback type="invalid">Needs a selection</Form.Control.Feedback>
              </Form.Check>
              <Form.Check inline>
                <Form.Check.Input
                  type="radio"
                  id="consentPostNo"
                  name="consentPost"
                  value="no"
                  label="No"
                  checked={props.orderData.consentPost === false}
                  onChange={handleConsentRadioChange}
                />
                <Form.Check.Label htmlFor="consentPostNo">No</Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </>
          }

          {props.orderData.consentEmail === false &&
          <div className="contact-opt-in-prompt">
            <p><strong>Are you sure?</strong></p>
            <p>Selecting &ldquo;no&rdquo; will change your contact preferences and we may no longer be allowed to contact you.</p>
            <p className="btn btn-primary mb-0" onClick={consentEmailOptIn}>Keep me involved</p>
          </div>
          }
        </>}

        {/* The "Payment" component also includes the non-payment option ¯\_(ツ)_/¯ */}
        <Payment
          giftData={props.giftData}
          orderData={{ ...props.orderData, gifts: [props.giftData] }}
          updateOrderData={props.updateOrderData}
          saveAndNextStep={props.saveAndNextStep}
          goToNextStep={props.goToNextStep}
          updateOrderCompletionData= { props.updateOrderCompletionData }
        />

      </Form>
    </Col>

  </Row>);
}

GiftFormSenderDetails.propTypes = {
  product: propTypes.object,
  giftData: propTypes.object,
  orderData: propTypes.object,
  prefillData: propTypes.object,
  updateGiftData: propTypes.func,
  updateOrderData: propTypes.func,
  handleGiftInputChange: propTypes.func,
  handleOrderInputChange: propTypes.func,
  saveAndNextStep: propTypes.func,
  goToNextStep: propTypes.func,
  updateOrderCompletionData: propTypes.func,
};
