import React from 'react';
import propTypes from 'prop-types';
import { Container } from 'react-bootstrap';

import Error from './Error';

class PageError extends React.Component {

  constructor (props) {
    super(props);

    if (props.staticContext && props.staticContext.status) {
      this.state = {
        message: props.staticContext.status.message || props.message || null,
        details: props.staticContext.status.details || props.details || null,
      };
    } else {
      this.state = {
        message: props.message || null,
        details: props.details || null,
      };
    }
  }

  static get propTypes() {
    return {
      hideCTA: propTypes.bool,
      staticContext: propTypes.object,
      message: propTypes.string,
      details: propTypes.string,
    };
  }

  render() {
    return <div>
      {/* Using a wrapper div prevents issues
          with SSR and  hydration */}
      <section className="PageError">
        <Container>
          <Error
            hideCTA={this.props.hideCTA}
            message={this.state.message}
            detail={this.state.details}
          />
        </Container>
      </section>
    </div>;
  }

}

export default PageError;
