import React from 'react';
import propTypes from 'prop-types';
import { Col, Container, Row, Image } from 'react-bootstrap';

import LoadingPlaceholder from './LoadingPlaceholder';
import PageError from './PageError';
import Product from './Product';

import { displayInputAsCurrency } from '../utilities/currency';
import { getGiftByHash } from '../utilities/api';
import { share } from '../utilities/tracking';
import setWindowTitle from '../utilities/set-window-title';

class PageGift extends React.Component {

  constructor(props) {
    super(props);

    // Set default state
    this.state = {
      status: 'loading',
      gift: null,
    };

    // Pick up and clear server-side provided context
    this.state.context = props.staticContext;
    if (typeof window !== 'undefined' && window.__INITIAL_CONTEXT__) {
      this.state.context = window.__INITIAL_CONTEXT__;
      window.__INITIAL_CONTEXT__ = false;
    }

    // If we already have context populate the state
    if (this.state.context && this.state.context.data) {

      if (this.state.context.data.gift && this.state.context.data.product) {
        this.state.status = 'okay';
        this.state.gift = this.state.context.data.gift;
      } else {
        // Most likely a missing product, display the SSR details
        this.state.status = 'error';
        this.state.gift = {}; // Needed to prevent the client-side lookup
        // (which we already know will fail)
      }
    }

    this._isMounted = false;
  }

  static get propTypes() {
    return {
      giftHash: propTypes.string,
      gift: propTypes.object,
      sender: propTypes.object,
      match: propTypes.object,
      staticContext: propTypes.object,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (!this.state.gift) {
      if (this.props.match.params.giftHash) {
        getGiftByHash(this.props.match.params.giftHash)
          .then((giftObj) => {
            if (typeof giftObj !== 'object') {
              throw new Error('Unexpected return value for gift hash lookup');
            }
            // Update the window title
            setWindowTitle('Your gift');
            this._isMounted && this.setState({
              gift: giftObj,
              status: 'okay',
            });
          })
          .catch(() => {
            this._isMounted && this.setState({
              gift: {},
              product: {},
              sender: {},
              status: 'error',
            });
          });
      } else {
        this._isMounted && this.setState({
          gift: {},
          product: {},
          sender: {},
          status: 'error',
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getShareHandler(sharePlatform) {
    return () => {
      share({
        sharePlatform,
        userType: 'recipient', // We're on the gift page
        location: 'Gift page',
      });
    };
  }

  render() {
    if (this.state.status === 'loading') {
      return <LoadingPlaceholder />;
    }

    if (this.state.status === 'error') {
      return <PageError staticContext={this.state.context} />;
    }

    const giftDisplayDetails = {
      message:
        this.state.gift.archived ?
          'This gift has now expired, and the details have been deleted for your protection.\n\nYou can send a new gift using the links on this page.' :
          this.state.gift.message,
      recipient: {
        name:
          this.state.gift.archived ?
            'Unknown recipient' :
            this.state.gift.recipient.name,
      },
      status: this.state.gift.status,
    };

    return (
      <div className="PageGift">
        <Container className="py-4">
          <h1>
            {this.state.gift.archived === true ?
              'Expired Gift' :
              'Your surprise has arrived!'
            }
          </h1>
          <p className="h3 mb-4">
            {this.state.gift.archived === true ?
              'This gift was sent over 12 months ago and has now expired' :
              `${this.state.gift.recipient.name}, you have been sent an ecard from ${(this.state.gift.fromName || 'a friend.')}`
            }
          </p>
          <Row className="pt-4">
            <Col xs={12} md={6}>
              <Product mode="gift" gift={giftDisplayDetails} productId={this.state.gift.productId} />
            </Col>
            <Col xs={12} md={6}>
              {this.state.gift.valueDisplay && this.state.gift.value > 0 &&
                <>
                  <Row>
                    <Col xs={12} sm={4} className="d-flex align-items-center justify-content-center">
                      <img src="../images/Add-Gift-icon.svg" alt="gift icon" className="giftpage-icon"></img>
                    </Col>
                    <Col xs={12} sm={8} className="d-flex align-items-center justify-content-center my-2 my-md-0">
                      <h5 className="mx-2">Your surprise includes a generous donation of {displayInputAsCurrency(this.state.gift.valueForDisplay || this.state.gift.value, true, true, true, true)} to Friends of the Earth</h5>
                    </Col>
                  </Row>
                  <p>Your donation to <a href="https://friendsoftheearth.uk/funding-and-governance/friends-earth-charitable-trust">Friends of the Earth Charitable Trust</a> will help us protect the wellbeing of people and planet, including our work to fight climate breakdown.</p>
                  <hr />
                </>
              }
              <h3 className="mb-4">Send your own surprise</h3>
              <Row>
                <Col xs={12} sm={8} className="d-flex align-items-center justify-content-center my-2 my-md-0">
                  <p className="my-2 my-md-0">Why not brighten up someone’s day by sending them a surprise of your own? Choose from our exclusive selection of birthday, thank you and other stunning designs.</p>
                </Col>
                <Col xs={12} sm={4} className="d-flex align-items-center justify-content-center">
                  <a href="/occasions" className="btn btn-primary text-uppercase">Send a card</a>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={6} className="d-flex align-items-center my-2 my-md-0">
                  <h3 className="m-md-0 mx-auto">Spread the word!</h3>
                </Col>
                <Col md={6} className="d-flex align-items-center justify-content-center">
                  {/* WHATSAPP SHARE LINK */}
                  <a onClick={this.getShareHandler('whatsapp')} className="no-link-decoration d-lg-none" target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?text=I%E2%80%99ve%20received%20an%20eco-friendly%2C%20paperless%20surprise%20from%20someone%20special!%20Want%20to%20send%C2%A0your%20own%C2%A0thoughtful%20message%20to%20anyone%20around%20the%20world%3F%20Try%20it%20out%20now.%20https%3A%2F%2Ffoe.uk%2Fama0p%0A">
                    <Image src="../images/social-icons/whatsapp.svg" alt="whatsapp icon" className="icon" />
                  </a>
                  {/* FACEBOOK SHARE LINK*/}
                  <a onClick={this.getShareHandler('facebook')} className="no-link-decoration" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Ffoe.uk%2Foalng">
                    <Image src="../images/social-icons/facebook.svg" alt="facebook icon" className="icon" />
                  </a>
                  {/* MESSENGER SHARE LINK */}
                  <a onClick={this.getShareHandler('fbmessenger')} className="no-link-decoration d-lg-none" target="_blank" rel="noopener noreferrer" href="fb-messenger://share/?link=https%3A%2F%2Ffoe.uk%2Foalng">
                    <Image src="../images/social-icons/messenger.svg" alt="messenger icon" className="icon" />
                  </a>
                  {/* TWITTER SHARE LINK */}
                  <a onClick={this.getShareHandler('twitter')} className="no-link-decoration" target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=I%27ve%20received%20an%20eco-friendly%2C%20paperless%20surprise%20from%20someone%20special%20via%20%40friends_earth%20Want%20to%20send%C2%A0your%20own%20message%20to%20anyone%20around%20the%20world%3F%20Try%20it%20out%20now.%20https%3A%2F%2Ffoe.uk%2Fbaol5">
                    <Image src="../images/social-icons/twitter.svg" alt="twitter icon" className="icon" />
                  </a>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={12} sm={4} className="d-flex align-items-center justify-content-center">
                  <img src="../foe-bagel.png" alt="Friends of the Earth logo" className="giftpage-icon"></img>
                </Col>
                <Col xs={12} sm={8} className="d-flex align-items-center justify-content-center my-2 my-md-0">
                  <p className="m-0">To learn more about Friends of the Earth and the work we do, please visit <a href="https://friendsoftheearth.uk/" target="_blank" rel="noopener noreferrer"><span>friendsoftheearth.uk</span></a></p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

}

export default PageGift;
