import React from 'react';
import propTypes from 'prop-types';

import Personalise from './GiftForm/Personalise';
import Donation from './GiftForm/Donation';
import Preview from './GiftForm/Preview';
import SenderDetailsAndPayment from './GiftForm/SenderDetailsAndPayment';
import Process from './GiftForm/Process';

const components = {
  Personalise,
  Donation,
  Preview,
  SenderDetailsAndPayment,
  Process,
};

export default function GiftForm(props) {
  const saveAndNextStep = (event) => {
    let form = document.getElementById('sender-details-form');
    if (typeof event !== 'undefined' ){
      event.preventDefault();
      event.stopPropagation();

      if ( event.currentTarget !== null ) {
        if (event.currentTarget.tagName === 'FORM') {
          form = event.currentTarget;
        } else if (event.currentTarget.length !== 0 &&
          typeof event.currentTarget.form !== 'undefined') {
          form = event.currentTarget.form;
        }
      }
    }

    if (form && form.length && form.checkValidity() === false) {
      if (form.reportValidity) {
        form.reportValidity();
      }
    } else {
      props.goToNextStep();
    }
  };

  const handleGiftInputChange = (e, id) => {
    const input = e.currentTarget;
    const [inputName] = input.id.split('-');
    let value = input.value;
    if (input.type === 'checkbox') {
      value = input.checked;
    }
    if (id) {
      props.updateGiftDataByRecipientId(id, { [inputName]: value });
    } else {
      props.updateGiftData({ [inputName]: value });
    }
  };

  const handleOrderInputChange = (e) => {
    const input = e.currentTarget;
    let value = input.value;
    if (input.type === 'checkbox') {
      value = input.checked;
    }
    props.updateOrderData({ [input.id]: value });
  };

  const FormStepComponent = components[props.formStepName];
  return (
    <div className={`GiftForm ${props.formStepName}`}>

      { props.formStep > 0 &&
        <p className="fake-link" onClick={ props.goToPreviousStep }>&lt; Previous step</p>
      }

      <FormStepComponent
        {...props}
        saveAndNextStep={saveAndNextStep}
        handleGiftInputChange={handleGiftInputChange}
        handleOrderInputChange={handleOrderInputChange}
      />

    </div>
  );
}

GiftForm.propTypes = {
  formStep: propTypes.number,
  formStepName: propTypes.string,
  updateOrderData: propTypes.func,
  updateGiftData: propTypes.func,
  updateGiftDataByRecipientId: propTypes.func,
  addRecipient: propTypes.func,
  goToNextStep: propTypes.func,
  goToPreviousStep: propTypes.func,
};

// Also passes props through to current sub-component, including
// product: propTypes.object,
// orderData: propTypes.object,
// giftData: propTypes.object,
// orderCompletionData: propTypes.object,
// updateOrderCompletionData: propTypes.func,
