import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export default function Input(props) {
  const {
    controlId,
    required = false,
    pattern = undefined,
    label,
    placeholder,
    help,
    helpDisplay = 'below',
    invalidText,
    onChange,
    defaultValue,
    type,
    validation = undefined,
    pristine = true,
  } = props;
  const [error, setError] = useState(false);

  // On first render check the starting value if we have one
  // hinthint: localstorage
  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      handleValidation(defaultValue);
    }
  }, []);

  const handleValidation = (value) => {
    if (required && value.length === 0) {
      // Run required validation first separately
      setError(true);
    } else if (typeof validation === 'function') {
      // Run the custom validation
      const valid = validation(value);
      setError(!valid);
    } else {
      setError(false);
    }
  };

  const handleBlur = (event) => {
    onChange(event);
    handleValidation(event.currentTarget.value);
  };

  const handleInputChange = (event) => {
    onChange(event);
    handleValidation(event.currentTarget.value);
  };

  const HelpElement = () => help ? <p className="small m-0">{help}</p> : null;
  const FeedbackElement = () => (error && !pristine) ? <Form.Control.Feedback type="invalid">{invalidText}</Form.Control.Feedback> : null;

  return (
    <Form.Group controlId={controlId} className={pristine || error ? '' : 'was-validated'}>
      {label && <Form.Label>{label}{required && '*'}</Form.Label>}
      {helpDisplay === 'above' && <HelpElement />}
      <Form.Control
        required={required}
        type={type}
        pattern={pattern}
        defaultValue={defaultValue}
        placeholder={placeholder || label || undefined}
        isInvalid={(error && !pristine)}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
      <FeedbackElement />
      {helpDisplay === 'below' && <HelpElement />}
    </Form.Group>
  );
}

Input.propTypes = {
  controlId: propTypes.string,
  required: propTypes.bool,
  pristine: propTypes.bool,
  label: propTypes.string,
  placeholder: propTypes.string,
  pattern: propTypes.string,
  help: propTypes.string,
  helpDisplay: propTypes.string,
  invalidText: propTypes.string,
  onChange: propTypes.func,
  onError: propTypes.func,
  defaultValue: propTypes.string,
  type: propTypes.string,
  validation: propTypes.func,
};
