import React from 'react';
import propTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ProductList from './ProductList';
import CategoryBanner from './CategoryBanner';
import LoadingPlaceholder from './LoadingPlaceholder';
import PageError from './PageError';

import { getCategoryBySlug } from '../utilities/api';

class Category extends React.Component {

  constructor (props) {
    super(props);
    if (props.category) {
      this.state = {
        status: 'okay',
        category: props.category,
      };
    } else {
      this.state = {
        status: 'loading',
        category: null,
      };
    }
    this._isMounted = false;
  }

  static get propTypes() {
    return {
      category: propTypes.object,
      categorySlug: propTypes.string,
      children: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node,
      ]),
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.state.category) {
      const slug = this.props.categorySlug || '';
      getCategoryBySlug(slug)
        .then( (value) => {
          if (!value) {
            throw new Error(`Category with slug "${slug}" not found`);
          }
          this._isMounted && this.setState({
            status: 'okay',
            category: value,
          });
        })
        .catch( (error) => {
          this._isMounted && this.setState({
            status: 'error',
            category: null,
          });
          console.error(error);
        } );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    // Assumes up to 2 child nodes ― be careful of whitespace when placing this component
    if (this.state.status === 'loading') {
      return <LoadingPlaceholder />;
    }
    if (this.state.status === 'error') {
      return <PageError />;
    }
    return (
      <section className="Category">
        <CategoryBanner category={ this.state.category } />
        <Container>
          <Row>
            <Col className="py-4">
              {
                (this.props.children && this.props.children[0]) ||
                <Link to="/occasions" className="h4">&lt; Browse all occasions</Link>
              }
            </Col>
          </Row>
        </Container>
        <ProductList categoryId={ this.state.category._id } categorySlug={ this.state.category.slug } />
        { this.props.children && this.props.children[1] ?
          <>
            <Container>
              <Row>
                <Col className="py-4">
                  {this.props.children[1]}
                </Col>
              </Row>
            </Container>
          </> :
          null
        }
      </section>
    );
  }

}

export default Category;
