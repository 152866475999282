import React from 'react';
import propTypes from 'prop-types';

import siteSettings from '../../config/settings';
import Input from '../Form/Input';
import Textarea from '../Form/Textarea';
import Delivery from '../Form/Delivery';
import { MdContentCopy } from 'react-icons/md';

import { nameRegex, emailRegex, emailPattern } from '../../utilities/regex-patterns';
export default function RecipientFormSingle(props) {
  const { recipient, iteration } = props;
  const handleDeliveryChange = (event) => {
    if (event && typeof event.currentTarget !== 'undefined') {
      const value = (event.currentTarget.value === 'yes');
      props.updateGiftDataByRecipientId(recipient.id, { immediateDelivery: value, scheduledSendTime: undefined });
    } else {
      props.updateGiftDataByRecipientId(recipient.id, { immediateDelivery: false, scheduledSendTime: event });
    }
  };

  const handleInputChange = (event) => {
    if (event.target !== document.activeElement &&
        (event.target.type == 'text' || event.target.type == 'email') ) {
      event.target.value = event.target.value.trim();
    }
    props.handleGiftInputChange(event, recipient.id);
  };

  const handleCopyMessageClick = () => {
    return props.handleCopyMessage(recipient.id);
  };

  const minScheduleDate = new Date();
  const maxScheduleDate = new Date(minScheduleDate.valueOf());
  maxScheduleDate.setFullYear(maxScheduleDate.getFullYear() + 1);
  maxScheduleDate.setDate(maxScheduleDate.getDate() + 1);

  return (<>
    {props.mainRecipient && props.onlyRecipient && <h2 className="mb-3 mb-lg-4">Personalise your card</h2>}

    <Input
      required
      controlId={`recipientName-${recipient.id}`}
      label="Recipient name"
      type="text"
      defaultValue={recipient.recipientName}
      pristine={recipient.formState.recipientName && recipient.formState.recipientName.pristine}
      onChange={handleInputChange}
      validation={value => nameRegex.test(value)}
      invalidText="Please enter a valid recipient name. Symbols are not allowed."
    />

    <Input
      required
      controlId={`recipientEmail-${recipient.id}`}
      label="Recipient email"
      type="email"
      placeholder="address@example.com"
      pattern={emailPattern}
      defaultValue={recipient.recipientEmail}
      pristine={recipient.formState.recipientEmail && recipient.formState.recipientEmail.pristine}
      onChange={handleInputChange}
      validation={value => emailRegex.test(value)}
      invalidText="Please enter a valid email address for this recipient."
      help="We’ll only use this email to send your card. Your recipient won’t be signed up to any further communications from Friends of the Earth."
      helpDisplay="above"
    />

    <div className={props.mainRecipient ? '' : 'bg-white p-4 mt-4'}>

      {
        !props.mainRecipient && <>
          <button className="btn btn-block btn-sm btn-outline-primary mb-4" onClick={handleCopyMessageClick}>
            <span aria-hidden focusable="false" role="presentation">
              <MdContentCopy className="mr-2" />
            </span>
            Use previous details
          </button>
        </>
      }

      <Textarea
        key={`message-${recipient.id}-${iteration}`}
        required
        controlId={`message-${recipient.id}`}
        label="Message"
        type="textarea"
        placeholder="Dear friend..."
        defaultValue={recipient.message}
        pristine={recipient.formState.message && recipient.formState.message.pristine}
        onChange={handleInputChange}
        invalidText="Please enter a message."
        rows="3"
        maxLength={siteSettings.maxMessageLength}
      />

      <Input
        key={`fromName-${recipient.id}-${iteration}`}
        required
        controlId={`fromName-${recipient.id}`}
        label="From"
        type="text"
        defaultValue={recipient.fromName}
        pristine={recipient.formState.fromName && recipient.formState.fromName.pristine}
        onChange={handleInputChange}
        validation={value => nameRegex.test(value)}
        invalidText="Please enter a valid name. Symbols are not allowed"
        help="We’ll use this name when we email the recipient, but you should add a signature to your card message."
        helpDisplay="above"
      />

      <Delivery
        controlId={`Delivery-${recipient.id}`}
        onChange={handleDeliveryChange}
        immediateDelivery={recipient.immediateDelivery}
        scheduledSendTime={recipient.scheduledSendTime}
      />

    </div>

  </>);
}

RecipientFormSingle.propTypes = {
  recipient: propTypes.object,
  iteration: propTypes.string,
  product: propTypes.object,
  giftData: propTypes.object,
  orderData: propTypes.object,
  updateGiftDataByRecipientId: propTypes.func,
  updateOrderData: propTypes.func,
  handleCopyMessage: propTypes.func,
  handleGiftInputChange: propTypes.func,
  handleOrderInputChange: propTypes.func,
  saveAndNextStep: propTypes.func,
  mainRecipient: propTypes.bool,
  onlyRecipient: propTypes.bool,
};
