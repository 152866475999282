import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import PlatformFeatures from './PlatformFeatures';
import Category from './Category';
import HomeBanner from './HomeBanner';
import AboutUs from './AboutUs';

import setWindowTitle from '../utilities/set-window-title';
import { sitename } from '../utilities/strings';

class PageHome extends React.Component {

  static get propTypes() {
    return {
      category: propTypes.object,
      config: propTypes.object,
      staticContext: propTypes.object,
    };
  }

  render () {
    // Update the window title
    setWindowTitle(sitename, false);

    return <>
      <HomeBanner
        decoration={parseInt(this.props.config.homepageDecorationImageActive) ? this.props.config.homepageDecorationImage : null}
        cardImage={this.props.config.homepageCardImage}
      />
      <PlatformFeatures />
      <Category categorySlug='featured'>
        <></>{/* Empty element for above the product list */}
        <p className="text-center">
          <Link to="/occasions" className="btn btn-primary mb-4">Browse all occasions</Link>
        </p>
      </Category>
      <AboutUs />
    </>;
  }

}

export default PageHome;
