import React from 'react';
import propTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import parse from 'html-react-parser';

import Error from './Error';
import StandardPanel from './panels/StandardPanel';

const Panel = (props) => {
  switch (props.data.type) {

    case 'standard':
      return <StandardPanel {...props} />;

    case 'markdown':
      return <Container><ReactMarkdown source={ props.data.text || '' }/></Container>;

    case 'html':
      return parse(props.data.text || '');

    default:
      return <Error hideCTA={ true }/>;

  }
};

Panel.propTypes = {
  data: propTypes.object,
};

export default Panel;
