// Simple date utils
// If our needs expand we should probably just bring a library in.

import siteSettings from '../config/settings';

const months = [
  { short: 'Jan', long: 'January' },
  { short: 'Feb', long: 'February' },
  { short: 'Mar', long: 'March' },
  { short: 'Apr', long: 'April' },
  { short: 'May', long: 'May' },
  { short: 'Jun', long: 'June' },
  { short: 'Jul', long: 'July' },
  { short: 'Aug', long: 'August' },
  { short: 'Sep', long: 'September' },
  { short: 'Oct', long: 'October' },
  { short: 'Nov', long: 'November' },
  { short: 'Dec', long: 'December' },
];

const days = [
  { short: 'Sun', long: 'Sunday' },
  { short: 'Mon', long: 'Monday' },
  { short: 'Tue', long: 'Tuesday' },
  { short: 'Wed', long: 'Wednesday' },
  { short: 'Thu', long: 'Thursday' },
  { short: 'Fri', long: 'Friday' },
  { short: 'Sat', long: 'Saturday' },
];

export const formatDateString = (dateString, format = siteSettings.defaultDateFormat || 'short') => {
  const date = new Date(dateString);
  const now = new Date();

  switch(format) {

    case 'concise':
      return `${date.getHours()}:${('0' + date.getMinutes()).substr(-2)} on ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

    case 'short':
    default:
      return `${date.getHours()}:${('0' + date.getMinutes()).substr(-2)} on ${days[date.getDay()].long} ${months[date.getMonth()].long} ${date.getDate()}${date.getFullYear() === now.getFullYear() ? '' : ', ' + date.getFullYear()}`;

  }
};

export default {
  formatDateString,
};
