import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class BrowserSupportPage extends React.Component {

  render() {
    return (
      <section className="BrowserSupportPage">
        <Container>
          <Row className="justify-content-center align-items-center text-center" style={{ height: '100vh' }}>
            <Col xs={12} md={9} lg={8}>
              <h4>Unsupported browser</h4>
              <h1 class="my-4">Internet Explorer is not supported by this website.</h1>
              <p class="lead">Internet Explorer is out of date and doesn't support modern standards, including modern security features.</p>
              <p class="my-4">You'll need to upgrade to a modern and secure browser to view this website. We recommend the options below.</p>
              <div><a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a> &nbsp; | &nbsp; <a href="https://www.google.co.uk/chrome/">Google Chrome</a> &nbsp; | &nbsp; <a href="https://www.mozilla.org/en-GB/firefox/new/">Mozilla Firefox</a></div>
              <p>&nbsp;</p>
              <p class="mt-4 pt-4 small text-grey">(If you think you're seeing this message in error, please <a target="_blank" rel="noopener noreferrer" href="https://friendsoftheearth.uk/contact?enquiry_type=general&enquiry_detail_general=gifts">let us know</a>.)</p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

}

export default BrowserSupportPage;
