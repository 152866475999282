import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';

import GiftAidPCA from '../GiftAidPCA';
import { displayInputAsCurrency } from '../../utilities/currency';
import Product from '../Product';

const donationPrompts = [2, 5, 10];
const donationPromptOther = 20;

export default function GiftFormDonate(props) {
  // Set default form state
  if (props.giftData.valueDisplay === undefined) {
    //
  }

  const [showGiftAidDiv, setShowGiftAidDiv] = useState(false);
  const [otherAmountConfirmationStyle, setOtherAmountConfirmationStyle] = useState({ display: 'none' });
  const [fakeOtherAmountSaveTimeout, setFakeOtherAmountSaveTimeout] = useState();

  const setDonationValue = value => () => {
    if (typeof props.giftData.valueDisplay === 'undefined') {
      props.updateGiftData({ valueDisplay: true });
    }
    if (props.giftData.value !== value) {
      props.updateGiftData({ value: value });
    } else {
      props.updateGiftData({ value: 0 });
    }
  };

  const setOtherDonationValue = (event) => {
    setOtherAmountConfirmationStyle({ display: 'none' });
    clearTimeout(fakeOtherAmountSaveTimeout);

    let otherValue = donationPromptOther;
    if (event.currentTarget.value !== undefined) {
      otherValue = event.currentTarget.value;
    } else if (props.giftData.otherValue !== undefined) {
      otherValue = props.giftData.otherValue;
    }

    otherValue = otherValue.toString().replace(/[^\d.]/g, '');
    if (event.type === 'click') {
      setDonationValue('other')();
    }
    props.updateGiftData({ otherValue: displayInputAsCurrency(otherValue, false, false) });
    if (otherValue > 0) {
      triggerFakeOtherAmountSave();
    }
  };

  const triggerFakeOtherAmountSave = () => {
    // Hide the tick, and after .75 seconds, show the tick
    setFakeOtherAmountSaveTimeout(setTimeout(function () {
      setOtherAmountConfirmationStyle({ display: 'block' });
    }, 750));
  };

  const skipDonation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.updateGiftData({ value: 0 });
    props.goToNextStep();
  };

  const giftData = Object.assign(
    {},
    props.giftData,
    {
      recipientName: props.giftData.recipients.length > 1 ?
        'Recipient name' :
        props.giftData.recipients.find(Boolean).recipientName,
      message: props.giftData.recipients.length > 1 ?
        'Individual messages\nwill appear here' :
        props.giftData.recipients.find(Boolean).message,
    }
  );

  return (

    <Row className="justify-content-center">
      <Col xs={12} md={5}>
        <Product
          mode="preview"
          previewMode="message"
          product={props.product}
          giftData={giftData}
        />
      </Col>
      <Col xs={12} md={6}>

        <Form noValidate onSubmit={props.saveAndNextStep}>

          <h2 className="mb-3 mb-lg-4">Would you like to include a donation to Friends of the Earth?</h2>

          <p> Add an optional donation to your card to make the perfect gift for any occasion. All donations go to Friends of the Earth Charitable Trust and help us protect the wellbeing of people and the planet.</p>

          {
            props.giftData.recipients.length > 1 ?
              <p><strong>You&apos;ll only be charged for a single donation when sending multiple cards,</strong> and you can choose whether or not to display this donation to your recipients.</p> :
              <p>You can choose whether or not to display this donation to your recipient.</p>
          }

          <Row className="donation-prompts justify-content-center pt-4 mt-4">

            {
              donationPrompts.map(
                (promptAmount, index) => (
                  <Col key={index} xs={8} sm={4} onClick={setDonationValue(promptAmount)} className={props.giftData.value === promptAmount ? 'active' : ''}>
                    <div className="donation-option">
                      <Row>
                        <Col xs={{ span: 8, offset: 2 }} sm={{ span: 12, offset: 0 }}>
                          <img src="../images/Add-Gift-icon.svg" alt="Add Gift" className="mx-auto img-fluid" />
                        </Col>
                      </Row>
                      <p className="btn btn-block btn-primary">Donate £{promptAmount}</p>
                    </div>
                  </Col>
                ),
              )
            }

            <Col xs={12} md={10} lg={8} className={props.giftData.value === 'other' ? 'active' : ''}>
              <div className="donation-option mt-4" onClick={setOtherDonationValue}>
                <Row>
                  <Col xs={4}>
                    <img src="../images/Add-Gift-icon.svg" alt="Add Gift" className="mx-auto img-fluid" />
                  </Col>
                  <Col xs={7} className="my-auto">
                    <p className="btn btn-block btn-primary">Other amount</p>
                    {props.giftData.value === 'other' &&
                      <div className="input-wrapper">
                        <input
                          required
                          onChange={setOtherDonationValue}
                          onClick={(e) => { e.stopPropagation(); }}
                          size="sm"
                          id="otherValue"
                          type="text"
                          value={displayInputAsCurrency(props.giftData.otherValue)}
                          pattern="£[1-9].*"
                          maxLength="14"
                          title="A minimum donation of £1 is required"
                        />
                        <label htmlFor="otherValue" className="input-icon" style={otherAmountConfirmationStyle}>&#10004;</label>
                      </div>
                    }
                  </Col>
                </Row>
              </div>
            </Col>


          </Row>

          {((props.giftData.value === 'other' && props.giftData.otherValue > 0) || props.giftData.value > 0) && <>

            <h2 className="my-3 my-lg-4">Donation details</h2>
            <Form.Check
              onChange={props.handleGiftInputChange}
              id="valueDisplay"
              label="Display this donation on the card"
              className="my-3"
              defaultChecked={props.giftData.valueDisplay}
            />

            <div className="gift-aid-section">
              <img src="../images/giftaid.png" alt="gift aid logo" className="gift-aid mb-3"></img>
              <h5>Increase your donation by <span>25%</span> with Gift Aid, at no extra cost to you.</h5>
              <Form.Group className="my-4">
                <Form.Check
                  onChange={props.handleOrderInputChange}
                  type="checkbox"
                  id="giftAid"
                  label="Yes, I want to Gift Aid all my eligible donations."
                  defaultChecked={props.orderData.giftAid} />
              </Form.Group>
              <p className="small">* I am a UK taxpayer. I understand that if I pay less Income Tax and/or Capital Gains Tax in the current tax year than the amount of Gift Aid claimed on my eligible donations I must pay the difference. I want to Gift Aid this donation and any eligible donations I make in the future or have made in the past 4 years to Friends of the Earth Charitable Trust.</p>

              {!showGiftAidDiv && <p className="gift-aid-text" onClick={() => setShowGiftAidDiv(!showGiftAidDiv)}>Show more about Gift Aid</p>}

              {showGiftAidDiv && <div className="gift-aid-details">
                <p><small>Gift Aid is reclaimed by the charity from the tax you pay for the current tax year boosting your donation by 25p of Gift Aid for every £1 that you donate.</small></p>
                <p><small>To qualify for Gift Aid, you must be a UK taxpayer and understand that if you pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all your donations in that tax year, it is your responsibility to pay any difference.</small></p>
                <p><small>If you were not a taxpayer throughout the whole of the last four years, but have since become one, please call us on 0800 581 051.</small></p>
                <p><small>Please notify Friends of the Earth if you:</small></p>
                <ul>
                  <li><small>Want to cancel this declaration</small></li>
                  <li><small>Change your name or home address</small></li>
                  <li><small>No longer pay sufficient tax on your income and/or capital gains</small></li>
                </ul>
                <p><small>If you pay Income Tax at the higher or additional rate and want to receive the additional tax relief due to you, you must include all your Gift Aid donations on your Self-Assessment tax return or ask HM Revenue and Customs to adjust your code.</small></p>
                <p className="gift-aid-text" onClick={() => setShowGiftAidDiv(!showGiftAidDiv)}>Hide Gift Aid details</p>
              </div>}

              {props.orderData.giftAid &&
                <GiftAidPCA
                  orderData={props.orderData}
                  onChange={props.handleOrderInputChange}
                  updateOrderData={props.updateOrderData}
                />}

            </div>

            <div className="text-center text-md-right">
              <div className="d-inline-flex flex-column">
                <button className="btn btn-block btn-outline-primary" onClick={skipDonation}>
                  Skip donation
                </button>
                <button className="btn btn-block btn-primary mb-2" type="submit">
                  Confirm {displayInputAsCurrency(props.giftData.value === 'other' ? props.giftData.otherValue : props.giftData.value, true, true, true, true)} donation
                </button>
              </div>
            </div>

          </>}

          {
            (
              props.giftData.value === undefined ||
              props.giftData.value === 0 ||
              (props.giftData.value === 'other' && !parseFloat(props.giftData.otherValue))
            ) && <>
              <p className="h4 text-center my-4">No donation amount chosen</p>
              <Row className="justify-content-center">
                <Col xs={12} sm={8} lg={6}>
                  <button className="btn btn-block btn-outline-primary" type="submit" onClick={skipDonation}>Skip donation</button>
                </Col>
              </Row>
            </>
          }

        </Form>
      </Col>
    </Row>

  );
}

GiftFormDonate.propTypes = {
  product: propTypes.object,
  giftData: propTypes.object,
  orderData: propTypes.object,
  updateGiftData: propTypes.func,
  updateOrderData: propTypes.func,
  handleGiftInputChange: propTypes.func,
  handleOrderInputChange: propTypes.func,
  saveAndNextStep: propTypes.func,
  goToNextStep: propTypes.func,
};
