export default {
  siteName: 'Friends of the Earth',
  endpoints: {
    base: 'https://dgp-nginx.docker.amazee.io/',
    api: 'https://dgp-nginx.docker.amazee.io/services/api/',
    content: 'https://dgp-nginx.docker.amazee.io/services/content/',
  },
  theme: {
    backgroundColor: '#cff0d1',
    logoImage: 'theme/foe-logo-white.png',
  },
  maxMessageLength: 280,
  chaos: false,
  payment: {
    environment: 'sandbox', // Braintree
  },
};
