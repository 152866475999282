import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

class LoadingPlaceholder extends React.Component {

  render() {
    return (
      <section className="LoadingPlaceholder">
        <Container>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Image src="../images/foe-earth.svg" />
              <span>Loading</span>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

}

export default LoadingPlaceholder;
