export default {
  siteName: 'Friends of the Earth',
  endpoints: {
    base: 'https://dgp-dev.friendsoftheearth.uk/',
    api: 'https://dgp-dev.friendsoftheearth.uk/services/api/',
    content: 'https://dgp-dev.friendsoftheearth.uk/services/content/',
  },
  theme: {
    backgroundColor: '#cff0d1',
    logoImage: 'theme/foe-logo-white.png',
  },
  maxMessageLength: 280,
  chaos: false,
  payment: {
    environment: 'sandbox', // Braintree
  },
};
