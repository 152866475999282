import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Row, Col, Form, Card, Collapse, Button } from 'react-bootstrap';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

import Product from '../Product';
import RecipientEditableFormSingle from './RecipientEditableFormSingle';

import { displayInputAsCurrency } from '../../utilities/currency';
import { validateAllRecipients } from '../../utilities/validate-recipients';

export default function GiftFormPreview(props) {
  const { recipients } = props.giftData;
  const [activeIndex, setActiveIndex] = useState(null);
  const [previewIndex, setPreviewIndex] = useState(0); // Doesn't get unset when collapsing all cards

  const donationFieldsDisplay = (props.giftData.value === 'other' && props.giftData.otherValue > 0) || props.giftData.value > 0;
  const refs = {};

  // TODO: Create a Card component.
  return (
    <Row className="justify-content-center">
      <Col xs={12} md={5}>
        <Product
          mode="preview"
          previewMode="gift"
          product={props.product}
          giftData={Object.assign({}, props.giftData.recipients[previewIndex], {
            value: props.giftData.value,
            valueDisplay: props.giftData.valueDisplay,
          })}
        />
      </Col>
      <Col xs={12} md={6}>
        <Form
          noValidate
          onSubmit={props.saveAndNextStep}
          ref={r => refs.form = r}
        >
          {
            recipients.map((recipient, index) => {
              return (
                <Card className={`Preview__card${(index === previewIndex) ? ' is-active' : ''}`} key={recipient.id}>
                  <Card.Header
                    className="show"
                    onClick={() => {
                      setPreviewIndex(index);
                      setActiveIndex(activeIndex !== index ? index : false);
                    }}
                  >
                    <Card.Title>Card for {recipient.recipientName}</Card.Title>
                    <Card.Subtitle>{recipient.recipientEmail}</Card.Subtitle>
                    <div className="card-accordion-button">
                      {activeIndex === index ? <BsChevronUp /> : <BsChevronDown />}
                    </div>
                  </Card.Header>

                  <Collapse in={activeIndex === index}>
                    <Card.Body>
                      <Row className="justify-content-center">
                        <Col xs={12}>
                          <RecipientEditableFormSingle
                            {...props}
                            recipient={recipient}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Collapse>
                </Card>
              );
            })
          }

          <div className="Preview__donation-details mt-5">
            <h5 className="mb-0">Donation details</h5>

            <hr />

            {donationFieldsDisplay ?
              <div className="d-flex align-items-start">
                <ul className="flex-grow-1">
                  <li><strong>Donation amount:</strong> {displayInputAsCurrency(props.giftData.value === 'other' ? props.giftData.otherValue || 0 : props.giftData.value || 0, true, true, true, true)}</li>
                  <li><strong>Display donation:</strong> {props.giftData.valueDisplay ? 'Yes' : 'No'}</li>
                  <li><strong>Gift Aid:</strong> {props.orderData.giftAid ? 'Yes' : 'No'}</li>
                </ul>
                <Button variant="outline-primary" size="sm" onClick={props.goToPreviousStep}>Edit</Button>
              </div> :
              <p>No donation (<span className="fake-link" onClick={props.goToPreviousStep}>add a donation</span>)</p>
            }
          </div>


          <div className="text-center text-md-right">
            <div className="d-inline-flex flex-column">
              <button className="btn btn-block btn-primary mt-4" disabled={!validateAllRecipients(props.giftData.recipients)} type="submit">
                Confirm details
              </button>
            </div>
          </div>

        </Form>
      </Col>
    </Row>
  );
}

GiftFormPreview.propTypes = {
  product: propTypes.object,
  giftData: propTypes.object,
  orderData: propTypes.object,
  updateGiftData: propTypes.func,
  updateOrderData: propTypes.func,
  handleGiftInputChange: propTypes.func,
  handleOrderInputChange: propTypes.func,
  saveAndNextStep: propTypes.func,
  goToPreviousStep: propTypes.func,
};
