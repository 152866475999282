// Client-side entrypoint

import 'react-app-polyfill/ie9'; //You can import the entry point for the minimal version you intend to support - ie if you import the IE9 entry point, this will include IE10 and IE11 support.
import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import Routes from './client/config/routes';
// import { loadableReady } from '@loadable/component';

import App from './client/App';
import * as serviceWorker from './client/serviceWorker';

import './client/master.css';

import prefill from './client/utilities/prefill';
prefill();

const doClientSideHydration = () => {
  hydrate(
    <BrowserRouter routes={Routes}><App /></BrowserRouter>,
    document.getElementById('root'),
  );
};


if (!window.ssrEnabled) {
  render(
    <BrowserRouter routes={Routes}><App /></BrowserRouter>,
    document.getElementById('root'),
  );
} else {
  if (!window.disableClientHydration) {
    doClientSideHydration();
  } else {
    window.hydrate = doClientSideHydration;
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
