import React from 'react';
import propTypes from 'prop-types';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Error extends React.Component {

  static get propTypes() {
    return {
      hideCTA: propTypes.bool,
      message: propTypes.string,
      detail: propTypes.string,
    };
  }

  render() {
    return (
      <div className="Error">
        <Row className="justify-content-center">
          <Col sm={10} md={6} className="text-center d-flex flex-column justify-content-center error-message py-5">

            <h1 className="my-3">Oh no!</h1>

            <p className=" h3 pb-4">{ this.props.message || 'Something’s gone wrong.' }</p>

            <p className=" h4 pb-4">{ this.props.detail || 'This error has been logged, and we’ll look into it as soon as possible.' }</p>

            { !this.props.hideCTA && <Link to="/">
              <button className="btn btn-primary">Go to the home page</button>
            </Link>
            }

          </Col>
          <Col xs={4} sm={8} md={6} className="d-flex flex-column justify-content-center">
            <Image src="../images/foe-earth.svg" alt="earth icon" className="img-fluid"/>
          </Col>
        </Row>
      </div>
    );
  }

}

export default Error;
