/* Editable.js
 *
 * Displays a text summary when `editMode` is false
 * Displays the provided children when `editMode` is true
 * When input `onBlur`, we will set `editMode` back to false
 */

import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

import propTypes from 'prop-types';

// Component accept text, summary values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
const Editable = ({
  text,
  type,
  label,
  summary,
  children,
  keepOpenOnBlur,
  editFunction,
  validateFunction,
}) => {
  const [editMode, setEditMode] = useState(false);

  // Toggles back and forth the editMode using the hook above
  // and makes a check if there's a validation function appended
  // in the props.
  // I use this only to prevent the setEditMode to be called if the validateFunction
  // returns false. Actual validation happens on the parent. Check an example on Preview.js
  const toggleEditMode = () => {
    // Trigger the validation only when the editMode is currently enabled (true) and is switching to disabled (false)
    if (editMode === true) {
      const valid = validateFunction ? validateFunction() : true;
      valid && setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  return (
    <section className={`Editable type-${type} was-validated`}>
      <div className="d-flex align-items-end">
        <div className="editable-data">
          <Form.Label>{label}</Form.Label>
          <div onBlur={keepOpenOnBlur ? null : () => setEditMode(false)}>
            {
              editMode ?
                children :
                <span className="summary">
                  {text || summary || <em className="text-danger">Missing data</em>}
                </span>
            }
          </div>
        </div>
        <div className="editable-button">
          <Button
            block
            variant={editMode ? 'primary' : 'outline-primary'}
            size="sm"
            as="span"
            className="my-1"
            onClick={editFunction || toggleEditMode}
          >
            {editMode ? 'Save' : 'Edit'}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Editable;

Editable.propTypes = {
  editFunction: propTypes.func,
  validateFunction: propTypes.func,
  keepOpenOnBlur: propTypes.bool,
  text: propTypes.string,
  type: propTypes.string,
  label: propTypes.string,
  summary: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]),
};

Editable.defaultProps = {
  keepOpenOnBlur: true,
};
