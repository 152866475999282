import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

export default function Delivery (props) {
  const {
    controlId,
    onChange,
    immediateDelivery = true,
    scheduledSendTime = undefined,
    skipLabel,
  } = props;

  const handleChange = (event) => {
    onChange(event);
  };

  const minScheduleDate = new Date();
  const maxScheduleDate = new Date(minScheduleDate.valueOf());
  maxScheduleDate.setFullYear(maxScheduleDate.getFullYear() + 1);
  maxScheduleDate.setDate(maxScheduleDate.getDate() + 1);

  return (
    <>
      <Form.Group controlId={`immediate-${controlId}`} className="mt-4 mb-0">

        {!skipLabel && <Form.Label className="d-block mb-2">Delivery</Form.Label>}
        <Form.Check
          inline
          id={`immediateDeliveryYes-${controlId}`}
          type="radio"
          value="yes"
          label="Immediate"
          checked={immediateDelivery === true}
          onChange={handleChange}
        />
        <Form.Check
          inline
          id={`immediateDeliveryNo-${controlId}`}
          type="radio"
          value="no"
          label="Scheduled"
          checked={immediateDelivery === false}
          onChange={handleChange}
        />
      </Form.Group>

      {immediateDelivery === false &&
      <>
        <Form.Group controlId={`date-${controlId}`}>
          <Form.Label>Scheduled delivery time</Form.Label>
          <DatePicker
            required
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="d MMMM yyyy h:mm aa"
            selected={
              typeof scheduledSendTime === 'object' ? scheduledSendTime :
                typeof scheduledSendTime === 'string' ? new Date(scheduledSendTime) :
                  new Date()
            }
            onChange={handleChange}
            minDate={minScheduleDate}
            maxDate={maxScheduleDate}
          />
        </Form.Group>
      </>
      }
    </>
  );
}

Delivery.propTypes = {
  controlId: propTypes.string,
  onChange: propTypes.func,
  immediateDelivery: propTypes.bool,
  skipLabel: propTypes.bool,
  scheduledSendTime: propTypes.oneOfType([propTypes.string, propTypes.object]),
};
