// If changes are made to the structure we can invalidate previously-stored data.
const foeDataVersion = '1';

export const storeFoeData = () => {
  // Stores source code and analytics parameters

  // Check data-structure version
  if (localStorage.getItem('foeDataVersion') !== foeDataVersion) {
    localStorage.setItem('foeDataVersion', foeDataVersion);
    localStorage.removeItem('foeData');
  }

  // Some timing variables
  const currentTimestamp = Date.now();
  const dataExpiryTimestamp = currentTimestamp - (7 * 24 * 60 * 60 * 1000); // 7 days ago, in milliseconds

  // Skeleton to hold any parameter data
  const parameterData = {
    analyticsCount: 0,
    analytics: {},
  };

  // Fetch current parameter data
  const params = new URLSearchParams(window.location.search);
  parameterData.source = params.get('source');
  [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ].forEach(param => (parameterData.analytics[param] = params.get(param)) && parameterData.analyticsCount++);

  // Collect any previous data and build up our object for storage
  const foeData = JSON.parse(localStorage.getItem('foeData')) ?? { sourceTimestamp: 0, analyticsTimestamp: 0, analytics: {} };

  // 'source' hangs around until it's replaced or expires (via sourceTimestamp)
  if (foeData.sourceTimestamp < dataExpiryTimestamp || parameterData.source) {
    foeData.source = parameterData.source;
    foeData.sourceTimestamp = currentTimestamp;
  }

  // 'analytics' data hangs around until it's replaced or expires (via analyticsTimestamp)
  if (foeData.analyticsTimestamp < dataExpiryTimestamp || parameterData.analyticsCount) {
    foeData.analytics = parameterData.analytics;
    foeData.analyticsTimestamp = currentTimestamp;
  }

  // Store our (possibly-) updated data object
  localStorage.setItem('foeData', JSON.stringify(foeData));
};

export const retrieveFoeData = () => {
  // Returns current source code and analytics parameters

  const { source, analytics } = JSON.parse(localStorage.getItem('foeData')) ?? { analytics: {} };

  return {
    source,
    analytics,
  };
};

export default {
  storeFoeData,
  retrieveFoeData,
};
