// Wrapper to push into a global data object, for collection by e.g. Google Analytics
// GA uses "dataLayer", so that's the default global variable name here.

// Push raw data to the array
export const push = (data, dataArrayName = 'dataLayer') => {
  const dataArray = window[dataArrayName] = window[dataArrayName] || [];
  dataArray.push(data);
};

// Push a custom event
// (FOE's tag manager is configured to pick up 'customEvent' events and log them into GA)
export const pushEvent = ({ event, category:eventCategory, action:eventAction, label:eventLabel, value:eventValue, ...extra }, dataArrayName) => {
  push({
    event: event || 'customEvent',
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
    ...extra,
  }, dataArrayName);
};

// Utility for basic normalization of strings
export const slugify = (original = 'undefined') =>
  original
    .toLowerCase()
    .replace(/[^a-z0-9- ]/g, '')
    .replace(/[ -]+/g, '-')
    .replace(/^-*|-*$/g, '');


// Helpers for some FOE-specific tracking

// Goal 1: Share
export const share = ({ sharePlatform, userType, location = 'Unknown', locationSlug }, dataArrayName) => {
  locationSlug = locationSlug || slugify(location);
  const eventData = {
    category: 'share',
    action: `dgp-${sharePlatform}-${userType}`,
    label: `${location} ${locationSlug}`,
  };
  pushEvent(eventData, dataArrayName);
};

// Goal 11: Ecard sent
export const productSend = ({ value = 0, productName = 'Unknown product', productSlug }, dataArrayName) => {
  const eventData = {
    category: 'webform',
    action: 'submission',
    label: `dgp-${value ? 'paid' : 'free'}: ${productName} [${productSlug || slugify(productName)}]`,
  };
  pushEvent(eventData, dataArrayName);
};

// Goal 19: GDPR - Email opt-in HY
// (Actually sends any and all GDPR info, but our GA goal only picks up email)
// Category: gdpr_consent
// Action: dp_[channel]_[choice] // email/post/phone // hy/hn
export const consent = ({ channel, choice }, dataArrayName) => {
  const eventData = {
    category: 'gdpr_consent',
    action: `dp_${channel}_${choice ? 'hy' : 'hn'}`,
  };
  pushEvent(eventData, dataArrayName);
};

// Goal 10: Gift Aid declaration
// Category: gift_aid_declaration
// Action: yes
export const giftaid = (_data, dataArrayName) => {
  const eventData = {
    category: 'gift_aid_declaration',
    action: 'yes',
  };
  pushEvent(eventData, dataArrayName);
};

// eCommerce
// Also fires Goal 13: Single gift
// (or potentially Goal 9: Regular gift)
// TODO: Construct 'products' from an array parameter to better handle multiple gifts
export const purchase = ({
  transactionId, // Format "dgp_[order hash]/[payment ref]"", e.g. "dgp_a52d9fe2/7s0es32j
  value,
  frequency = 'cash',
  count = 1,
  productName = 'Unknown product',
  productSlug,
  productId,
}, dataArrayName) => {
  productSlug = productSlug || slugify(productName);

  const ecommerce = {
    purchase: {
      actionField: {
        id: transactionId,
        revenue: value,
      },
      products: [
        {
          name: `[dgp] ${productName}`,
          id: productId,
          price: value / count,
          quantity: count,
        },
      ],
    },
  };

  const eventData = {
    event: 'dgp-donation',
    category: 'donation',
    action: `purchase_${frequency}`,
    label: `dgp: ${productName} [${productSlug}]`,
    value: value,
    ecommerce,
  };

  pushEvent(eventData, dataArrayName);
};

export default {
  push,
  pushEvent,
  share,
  productSend,
  consent,
  giftaid,
  purchase,
};
