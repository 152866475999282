// Parse URL fragment if it looks like a userData prefill fragment
// Load object into sessionStorage under 'prefill'

// Procedural, runs on initial page load.

const prefillWhitelist = [
  'activeopt',
  'firstName',
  'lastName',
  'email',
  'postcode',
];

const prefill = () => {
  // This has no place in SSR
  if (typeof window === 'undefined') {
    return;
  }

  if (window.location.hash.substr(0, 3) === '#p:') {
    const prefill = (sessionStorage.getItem('prefillData') && JSON.parse(sessionStorage.getItem('prefillData'))) || {};
    const parsedHash = new URLSearchParams(
      window.location.hash.substr(3), // skip the first chars (#p:)
    );

    parsedHash.forEach( (value, key) => {
      if (prefillWhitelist.indexOf(key) !== -1) {
        prefill[key] = value;
      }
    });

    sessionStorage.setItem('prefillData', JSON.stringify(prefill));
    window.history.pushState('', document.title, window.location.pathname + window.location.search);
  }
};

export default prefill;
