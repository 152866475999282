import React, { createRef } from 'react';
import propTypes from 'prop-types';

import { displayInputAsCurrency } from '../../utilities/currency';

class ProductMessage extends React.Component {

  static get propTypes() {
    return {
      status: propTypes.string,
      product: propTypes.object,
      gift: propTypes.object,
      contentEndpoint: propTypes.string,
      isButton: propTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.messageWrapper = createRef();
    this.updateCSSVariables = this.updateCSSVariables.bind(this);
  }

  updateCSSVariables () {
    const messageWrapper = this.messageWrapper.current;
    if (this.props.isButton) {
      // We set the button size variable at the root level, because we
      // can't calculate per-instance when a button is hidden, e.g. during
      // multi-recipient personalisation (but they ARE all the same size!)
      if (messageWrapper.offsetWidth) {
        // ...But only set it when we actually have a width though!
        // Otherwise the hidden buttons clobber with zero values.
        document.documentElement.style.setProperty(
          '--preview-mode-button-container-width',
          messageWrapper.offsetWidth + 'px',
        );
        document.documentElement.style.setProperty(
          '--preview-mode-button-container-height',
          messageWrapper.offsetHeight + 'px',
        );
      }
    } else {
      // Set it directly for this message
      messageWrapper.style.setProperty(
        '--container-width',
        messageWrapper.offsetWidth + 'px',
      );
      messageWrapper.style.setProperty(
        '--container-height',
        messageWrapper.offsetHeight + 'px',
      );
    }
  }

  componentDidMount () {
    this.updateCSSVariables();
    window.addEventListener('resize', this.updateCSSVariables);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateCSSVariables);
  }

  render () {
    return (
      <div ref={this.messageWrapper} className="ProductMessage">
        <div className="message-outer">
          <div className="message-inner">
            <p className="text-center">{ 
              this.props.gift.archived === true ?
                'Your message here' :
                this.props.gift.message.replace(/[\n\s]*$/, '') 
            }</p>
          </div>
        </div>

        {
          !!(this.props.gift.valueDisplay && this.props.gift.value) &&
          <div className="gift-display">
            <img src="../images/Add-Gift-icon.svg" alt="Add Gift"/>
            <p>
              Your gift includes a <strong>{displayInputAsCurrency(this.props.gift.valueForDisplay || this.props.gift.value, true, true, true, true)}&nbsp;donation</strong>
              <br />
              to Friends&nbsp;of&nbsp;the&nbsp;Earth to help
              <br />
              protect people and planet.
            </p>
          </div>
        }

      </div>
    );
  }

}

export default ProductMessage;
