import React from 'react';
import propTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import siteSettings from '../config/settings';
class SiteFooter extends React.Component {

  static get propTypes() {
    return {
      menu: propTypes.object,
    };
  }

  render() {
    return (
      <footer className="SiteFooter">
        <Container>
          <Row>
            <Col sm={12} md={3} className="mb-3 mb-md-0">
              <Row>
                <Col xs={6} sm={4} md={12} className="my-3 my-md-0">
                  <img
                    className="logo"
                    src={
                      siteSettings.endpoints.content +
                      siteSettings.theme.logoImage
                    }
                    title={siteSettings.siteName}
                    alt={siteSettings.siteName + ' logo'}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={4} md={3} className="my-3 my-md-0">
              <h3>Help</h3>
              <p><a target="_blank" rel="noopener noreferrer" href="https://friendsoftheearth.uk/who-we-are">About us</a></p>
              <p><Link to="/faq">FAQs</Link></p>
              <p><a target="_blank" rel="noopener noreferrer" href="https://friendsoftheearth.uk/contact?enquiry_type=general&enquiry_detail_general=gifts">Contact us</a></p>
            </Col>
            <Col xs={12} sm={4} md={3} className="my-3 my-md-0">
              <h3>The small stuff</h3>
              <p><Link to="/cookies">Cookies</Link></p>
              <p><a href="https://friendsoftheearth.uk/about-us/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy policy</a></p>
            </Col>
            <Col xs={12} sm={4} md={3} className="my-3 my-md-0">
              <h3>Payment methods</h3>
              <div className="d-flex">
                <img src="/images/visa.png" alt="The Visa logo" className="paymentIcon" />
                <img src="/images/master-card.png" alt="The Mastercard logo" className="paymentIcon" />
                <img src="/images/paypal.png" alt="The PayPal logo" className="paymentIcon" />
                <img src="/images/applepay.png" alt="The Apple Pay logo" className="paymentIcon" />
                <img src="/images/googlepay.png" alt="The Google Pay logo" className="paymentIcon" />
              </div>
            </Col>
            <hr />
            <Col md={12} className="my-3 py-2 foe-footer-links">
              <p>Friends of the Earth Limited (Reg. No. 1012357) is incorporated in England and Wales. Registered office: 1st Floor, The Printworks, 139 Clapham Road, London, SW9 0HP</p>
              <p>Friends of the Earth Limited (“Friends of the Earth”) receives grants from <a href="https://friendsoftheearth.uk/funding-and-governance/friends-earth-charitable-trust" target="_blank" rel="noopener noreferrer">Friends of the Earth Charitable Trust</a>, a registered charity in England and Wales with charity number 281681</p>
              <p>© <a href="https://friendsoftheearth.uk/about-us/copyright" target="_blank" rel="noopener noreferrer">Friends of the Earth Limited</a> | <a href="https://friendsoftheearth.uk/about-us/privacy-policy" target="_blank" rel="noopener noreferrer">Friends of the Earth privacy policy</a></p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }

}

export default SiteFooter;
