import React from 'react';
import propTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import Gift from './Gift';

import { formatDateString } from '../utilities/date';

class Order extends React.Component {

  static get propTypes() {
    return {
      order: propTypes.object,
    };
  }

  render () {
    if (this.props.order && this.props.order.gifts && this.props.order.gifts.length) {
      return (
        <div className="Order">
          <h2>Order #{parseInt(this.props.order.hash, 16)}</h2>
          <p class="lead">Created at { formatDateString(this.props.order.createdAt) }</p>
          <Row>
            {
              this.props.order.gifts.map( (gift, key) => {
                return (
                  <Col xs={12} lg={6} key={key} className="mt-4">
                    <Gift gift={gift} key={key} />
                  </Col>
                );
              })
            }
          </Row>
        </div>
      );
    }
    return (
      <p>No gift data is available for this order.</p>
    );
  }

}

export default Order;
