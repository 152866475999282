import React from 'react';
import propTypes from 'prop-types';

import Animation from '../Animation';
import LoadingPlaceholder from '../LoadingPlaceholder';
import PageError from '../PageError';

class ProductGift extends React.Component {

  static get propTypes() {
    return {
      status: propTypes.string,
      product: propTypes.object,
      gift: propTypes.object,
      orderData: propTypes.object,
    };
  }

  render () {
    if (this.props.status === 'loading') {
      return <LoadingPlaceholder />;
    }

    if (this.props.status === 'error') {
      return <PageError />;
    }

    // TODO: Why is this based off the gift's status??
    // (The current behaviour has been leveraged in PageStatic.js as well as the
    // related ssr-data-load.js code, but feels like it should be refactored...)
    const controls = (this.props.gift.status === 'sent');

    return (
      <div className="ProductGift">
        <Animation
          status={this.props.status}
          product={this.props.product}
          gift={this.props.gift}
          config={{
            showControls: controls,
          }}
        />

      </div>
    );
  }

}

export default ProductGift;
