import React from 'react';
import propTypes from 'prop-types';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Panel from './Panel';
import Animation from './Animation';
import LoadingPlaceholder from './LoadingPlaceholder';
import PageError from './PageError';

import { displayInputAsCurrency } from '../utilities/currency';
import { formatDateString } from '../utilities/date';
import { getOrderByHash, getPanelsByType } from '../utilities/api';
import { share } from '../utilities/tracking';
import setWindowTitle from '../utilities/set-window-title';

class PageThanks extends React.Component {

  constructor(props) {
    super(props);

    // Pick up and clear server-side provided context
    this.context = props.staticContext;
    if (typeof window !== 'undefined' && window.__INITIAL_CONTEXT__) {
      this.context = window.__INITIAL_CONTEXT__;
      window.__INITIAL_CONTEXT__ = false;
    }

    // Set default state
    this.state = {
      status: 'loading',
      order: null,
      errorMessage: null,
      panels: [],
    };

    // If we already have context populate the state
    if (this.context && this.context.data && this.context.data.order) {
      this.state.status = 'okay';
      this.state.order = this.context.data.order;
      this.state.panels = this.context.data.panels || [];
    }
  }

  static get propTypes() {
    return {
      orderHash: propTypes.string,
      match: propTypes.object,
      staticContext: propTypes.object,
    };
  }

  getDeliveryDisplay(gifts) {
    let timestamp = gifts[0].scheduledSendTime;
    for (let i = 1; i < gifts.length; i++) {
      if (gifts[i].scheduledSendTime !== timestamp) {
        timestamp = false;
        break;
      }
    }
    if (timestamp) {
      return formatDateString(timestamp, 'concise');
    }
    return '(Multiple delivery times)';
  }


  getDonationDisplay(amount) {
    if (!amount) {
      return 'No donation';
    }
    return displayInputAsCurrency(amount, true, true, true, true);
  }

  getPaymentMethod(method) {
    switch (method) {

      case 'android_pay_card':
        return 'Google Pay';

      case 'apple_pay_card':
        return 'Apple Pay';

      case 'paypal_account':
        return 'PayPal';

      case 'credit_card':
        return 'Credit/Debit Card';

      case '':
        return 'No donation';

      default:
        return 'Generic';

    }
  }


  componentDidMount() {
    this._isMounted = true;
    // Update the window title
    setWindowTitle('Confirmation');
    if (!this.state.order) {
      if (this.props.match.params.orderHash) {
        getOrderByHash(this.props.match.params.orderHash)
          .then((orderObj) => {
            if (typeof orderObj !== 'object') {
              throw new Error('Unexpected return value for order hash lookup');
            }
            getPanelsByType('thanks', { productId: orderObj.gifts[0].productId })
              .then((panels) => {
                this._isMounted && this.setState({
                  order: orderObj,
                  status: 'okay',
                  panels,
                });
              });
          })
          .catch(() => {
            this._isMounted && this.setState({
              order: {},
              status: 'error',
              errorMessage: 'Something went wrong displaying your order details',
              errorDetails: 'Please check your inbox for emailed confirmation of your order, and if you have any concerns you can use the "contact us" link in the website\'s footer to get in touch.',
            });
          });
      } else {
        this._isMounted && this.setState({
          order: {},
          status: 'error',
        });
      }
    }
  }

  getShareHandler(sharePlatform) {
    return () => {
      share({
        sharePlatform,
        userType: 'sender', // We're on the gift page
        location: 'Thanks page',
      });
    };
  }

  render() {
    if (this.state.status === 'loading') {
      return <LoadingPlaceholder />;
    }

    if (this.state.status === 'error') {
      return <PageError message={this.state.errorMessage} details={this.state.errorDetails} />;
    }

    let panelIndex = 0;

    return (
      <section className="PageThanks">

        <Container>
          <Row className="justify-content-center success-message">
            <Col lg={6} className="py-2 py-lg-5 text-center">
              {// Fake up product and gift details for the animation
              }
              <Animation
                status="okay"
                config={{
                  interactive: false,
                  autoTrigger: false,
                  initialStateOverride: {
                    isEnvelopeActive: true,
                  },
                }}
                product={{
                  assets: {
                    image: 'theme/thank-you-page-card.jpg',
                  },
                }}
                gift={{
                  recipient: {
                    name: this.state.order.sender.firstName,
                  },
                  message: 'Thanks for using our super neat-o system!',
                }}
              />
            </Col>
            <Col lg={6} className="py-2 py-lg-5">
              <h1 className="mb-4">Thanks {this.state.order.sender.firstName}</h1>

              <p>A confirmation email has been sent to <strong>{this.state.order.sender.email}</strong> and

                {this.state.order.gifts.length === 1 ?
                  <span> your gift for <strong>{this.state.order.gifts[0].recipient.name}</strong> will be sent at the time you&apos;ve chosen.</span> :
                  <span> your gifts will be sent immediately or at the scheduled time(s).</span>
                }</p>

              <Row> {// Row needed to wrap columns that change order on mobile
              }

              <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 2 }} className="p-3">

                <h3>Order details</h3>

                <table className="order-details"><tbody>
                  {
                    [
                      { label: 'Reference', value: `#${parseInt(this.state.order.hash, 16)}` },
                      ...(this.state.order.value ? [{ label: 'Donation', value: this.getDonationDisplay(this.state.order.value) }] : []),
                      ...(this.state.order.paymentMethod ? [{ label: 'Payment Method', value: this.getPaymentMethod(this.state.order.paymentMethod) }] : []),
                      { label: 'Quantity', value: this.state.order.gifts.length },
                      { label: 'Delivery', value: this.getDeliveryDisplay(this.state.order.gifts) },
                    ].map((row, index) => <tr key={index}><td>{row.label}</td><td>{row.value}</td></tr>)
                  }
                </tbody></table>

                <p>Your <strong>confirmation email</strong> contains a link to your recent orders, including the details for each gift in this order.</p>

                <Link to="/occasions" className="btn btn-primary text-uppercase my-2">
                    Send another
                </Link>
              </Col>

              </Row>
            </Col>
          </Row>
        </Container>

        <div className="social-links">
          <Container className="py-2">
            <h3 className="h5 my-2">Spread the word</h3>

            {/* WHATSAPP SHARE LINK */}
            <a onClick={this.getShareHandler('whatsapp')} className="no-link-decoration d-lg-none" target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?text=I%E2%80%99ve%20sent%20an%20eco-friendly%2C%20paperless%20surprise%20to%20brighten%20someone%E2%80%99s%20day%20via%20Friends%20of%20the%20Earth!%C2%A0Want%20to%20send%20your%20own%20thoughtful%20message%20to%20anyone%20around%20the%20world%3F%20Try%20it%20out%20now%20https%3A%2F%2Ffoe.uk%2F29gfv%0A">
              <Image src="../images/social-icons/whatsapp.svg" alt="whatsapp icon" className="icon" />
            </a>

            {/* FACEBOOK SHARE LINK */}
            <a onClick={this.getShareHandler('facebook')} className="no-link-decoration" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Ffoe.uk%2Fnva2a">
              <Image src="../images/social-icons/facebook.svg" alt="facebook icon" className="icon" />
            </a>

            {/* MESSENGER SHARE LINK */}
            <a onClick={this.getShareHandler('fbmessenger')} className="no-link-decoration d-lg-none" target="_blank" rel="noopener noreferrer" href="fb-messenger://share/?link=https%3A%2F%2Ffoe.uk%2Fnva2a">
              <Image src="../images/social-icons/messenger.svg" alt="messenger icon" className="icon" />
            </a>

            {/* TWITTER SHARE LINK */}
            <a onClick={this.getShareHandler('twitter')} className="no-link-decoration" target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=I%27ve%20sent%20an%20eco-friendly%2C%20paperless%20surprise%20to%20brighten%20someone%27s%20day%20via%20%40friends_earth!%C2%A0Want%20to%20send%20your%20own%20thoughtful%20message%20to%20anyone%20around%20the%20world%3F%20Try%20it%20out%20now.%20https%3A%2F%2Ffoe.uk%2F6ow6a">
              <Image src="../images/social-icons/twitter.svg" alt="twitter icon" className="icon" />
            </a>

          </Container>
        </div>


        { this.state.panels.thanks_always_first &&
          this.state.panels.thanks_always_first.map((panel, index) => {
            return <Panel key={index} panelIndex={panelIndex++} data={panel} needsContainer={true} wrapperClass="thanks-always-first" />;
          })}


        { this.state.panels.custom &&
          this.state.panels.custom.map((panel, index) => {
            return <Panel key={index} panelIndex={panelIndex++} data={panel} />;
          })}


        { this.state.panels.thanks_always &&
          this.state.panels.thanks_always.map((panel, index) => {
            return <Panel key={index} panelIndex={panelIndex++} data={panel} />;
          })}


        { // Organisation panels, only displayed if we don't have any panels
          // within the 'custom' section. Varies based on whether there was
          // a donation made with the current order. (Showing panels from
          // 'thanks_donation' or 'thanks_no_donation', as set in config.)

          this.state.panels.custom.length ?
            null :
            (
              this.state.order.value ?
                (this.state.panels.thanks_donation &&
                  this.state.panels.thanks_donation.map((panel, index) => {
                    return <Panel key={index} panelIndex={panelIndex++} data={panel} />;
                  })) :
                (this.state.panels.thanks_no_donation &&
                  this.state.panels.thanks_no_donation.map((panel, index) => {
                    return <Panel key={index} panelIndex={panelIndex++} data={panel} />;
                  }))
            )
        }


        { this.state.panels.thanks_always_last &&
          this.state.panels.thanks_always_last.map((panel, index) => {
            return <Panel key={index} panelIndex={panelIndex++} data={panel} />;
          })
        }

      </section >
    );
  }

}

export default PageThanks;
