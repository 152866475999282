import React from 'react';
import propTypes from 'prop-types';

import siteSettings from '../config/settings';
import { getProductById } from '../utilities/api';

// Product render modes, selected via the 'mode' prop
import Preview from './Product/Preview';
import Modal from './Product/Modal';
import Gift from './Product/Gift';
import Full from './Product/Full';
import Thumbnail from './Product/Thumbnail';
import Message from './Product/Message';

const components = {
  Preview,
  Modal,
  Gift,
  Full,
  Thumbnail,
  Message,
};

const defaultRecipientName = 'Recipient';
const defaultmessage = 'Your message here';

class Product extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      product: props.product || null,
      status: props.product ? 'okay' : 'loading',
    };
    this._isMounted = false;
  }

  static get propTypes() {
    return {
      product: propTypes.object,
      gift: propTypes.object,
      giftData: propTypes.object,
      productId: propTypes.string,
      animationOffset: propTypes.number,
      mode: propTypes.oneOf([
        'thumbnail',
        'modal',
        'preview',
        'full',
        'gift',
        'message',
      ]),
      thumbnailLink: propTypes.bool,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    // If we received a product object, use it as-is
    if (this.props.product && (this.props.product._id || this.props.product.id)) {
      this._isMounted && this.setState({
        status: 'okay',
        product: this.props.product,
      });
      return;
    }

    // If we only have a productId instead, we'll need to load the product details in
    if (this.props.productId) {
      getProductById(this.props.productId)
        .then( (value) => {
          if (!value || (value.code && value.code === 404)) {
            throw new Error(`Product with id "${this.props.productId}" not found`);
          }
          this._isMounted && this.setState({
            product: value,
            status: 'okay',
          });
        })
        .catch( (error) => {
          this._isMounted && this.setState({
            product: null,
            status: 'error',
          });
          console.error(error);
        });
      return;
    }

    // If we get to here something's gone horribly wrong
    if (this._isMounted) {
      this.setState({
        product: null,
        status: 'error',
      });
    }
    return;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const mode = this.props.mode[0].toUpperCase() + this.props.mode.slice(1);
    const ProductComponent = components[mode];

    const gift = this.props.gift || {};
    if (this.props.giftData) {
      gift.message = this.props.giftData.message || defaultmessage;
      gift.recipient = {
        name: this.props.giftData.recipientName || defaultRecipientName,
      };
      gift.value = this.props.giftData.value === 'other' ? this.props.giftData.otherValue : this.props.giftData.value;
      gift.valueDisplay = gift.value && this.props.giftData.valueDisplay;
    }

    return (
      <div className="Product">
        <ProductComponent
          {...this.props}
          product={this.state.product}
          status={this.state.status}
          contentEndpoint={siteSettings.endpoints.content}
          giftData={this.props.giftData || {}}
          gift={gift}
        />
      </div>
    );
  }

}

export default Product;
