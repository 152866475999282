export const sitename = 'Ecogifts from Friends of the Earth';

// Used for a dirty int-to-string lookup
export const ordinals = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
];
