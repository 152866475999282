import React from 'react';
import propTypes from 'prop-types';

import LoadingPlaceholder from '../LoadingPlaceholder';
import ErrorPanel from '../ErrorPanel';

class ProductFull extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalActive: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  static get propTypes() {
    return {
      status: propTypes.string,
      product: propTypes.object,
      animationOffset: propTypes.number,
      thumbnailLink: propTypes.bool,
      contentEndpoint: propTypes.string,
    };
  }

  toggleModal() {
    this.setState({
      modalActive: !this.state.modalActive,
    });
  }

  render () {
    if (this.props.status === 'loading') {
      return <LoadingPlaceholder />;
    }

    let name;
    let description;
    let productAsset;

    if (this.props.status === 'error') {
      name = 'Product not found';
      description = 'Product not found';
      productAsset = (
        <ErrorPanel variant="square">
          <p>There was an error loading this product</p>
        </ErrorPanel>
      );
    } else {
      name = this.props.product.name;
      description = this.props.product.description;
      productAsset = <img
        className="image"
        src={
          this.props.contentEndpoint +
          this.props.product.assets.image
        }
        title={ name }
        alt={ description.trim() }
      />;
    }

    return (<div className="ProductFull">
      { productAsset }
    </div>);
  }

}

export default ProductFull;
