import React from 'react';
import propTypes from 'prop-types';

class SiteContent extends React.Component {

  static get propTypes() {
    return {
      children: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node,
      ]),
    };
  }

  render () {
    return (
      <section className="SiteContent">
        { this.props.children }
      </section>
    );
  }

}

export default SiteContent;
