import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Row, Col, Card, Collapse, Button } from 'react-bootstrap';
import Scroll from 'react-scroll';
import { RiCloseCircleFill } from 'react-icons/ri';

import Product from '../Product';
import RecipientFormSingle from './RecipientFormSingle';

import { validateRecipient, validateRecipientForm, validateAllRecipients } from '../../utilities/validate-recipients';

const scroll = Scroll.animateScroll;

const maxRecipients = 10;
export default function GiftFormPersonalise(props) {
  // Oh man, you know what this project needs? Redux.
  const { recipients } = props.giftData;
  const [activeIndex, setActiveIndex] = useState(recipients.length > 1 ? null : 0);
  const [confirmRemovalActiveIndex, setConfirmRemovalActiveIndex] = useState(null);

  // Used to trigger a repaint after external updates to the
  // message/fromName (e.g. via the copy function)
  const [iteration, setIteration] = useState(0);

  // Scroll to the currently-expanded recipient form.
  // We add a slight delay so the UI is clearer -- expansion/contraction
  // finishes before we scroll.
  useEffect(() => {
    if (typeof activeIndex === 'number') {
      setTimeout(() => {
        scrollToRecipient(activeIndex);
      }, 500);
    }
  }, [activeIndex]);


  // Wrapper to make sure we mark any previous fields as non-pristine
  // before changing to (and scrolling to) a different recipient
  const updateActiveIndex = (index) => {
    if (typeof activeIndex === 'number') {
      props.markRecipientDataDirty(activeIndex);
    }
    setActiveIndex(index);
  };

  const scrollToRecipient = (index) => {
    // Use vanilla getElementById() instead of faffing with a ref array
    const id = props.giftData.recipients[index].id;
    const card = document.getElementById(`recipient-details-${id}`);
    scroll.scrollTo(
      card.getBoundingClientRect().top + window.scrollY - 10, // Make border and some margin visible
      {
        smooth: true,
        duration: 500,
      },
    );
  };

  const RemoveButton = index => (
    <div className="card-remove-section">
      <button className="card-remove-button" onClick={getRemoveRecipientButtonHandler(index)}>
        <RiCloseCircleFill />
      </button>
      { confirmRemovalActiveIndex === index &&
        <div className="recipient-removal-confirmation">
          <p className="text-center">Delete this recipient?</p>
          <Button variant="secondary" className="mr-2" onClick={getRemoveRecipientButtonHandler(index)}>Cancel</Button>
          <Button variant="danger" onClick={() => { confirmRemoveRecipient(index); }}>Delete</Button>
        </div>
      }
    </div>
  );

  // Imagine if this project had redux...
  const handleAddRecipient = async () => {
    const index = await props.addRecipient();
    updateActiveIndex(index);
  };

  // Just imagine...
  const getRemoveRecipientButtonHandler = (index) => {
    return () => {
      setConfirmRemovalActiveIndex(confirmRemovalActiveIndex === index ? null : index);
    };
  };

  // Just imagine...
  const confirmRemoveRecipient = async (index) => {
    setConfirmRemovalActiveIndex(null);
    await props.removeRecipient(index);

    if (activeIndex === index) {
      setActiveIndex(null);
    }

    if (activeIndex > index) {
      setActiveIndex(activeIndex - 1);
    }

    // Force open at least one card if we remove all but one
    if (recipients.length === 1) {
      setActiveIndex(0);
    }
  };

  const handleSaveAndNextStep = () => {
    // TODO or maybe not: Reforce a check here.
    // Ideal: dispatch an event to Redux and retrieve the status of each form. Ideally. Yeah.

    // Realistic: scan all values and validate them against same patterns used in the forms so either
    // put patterns here and pass the props all the way down to the forms again or maybe
    // do a simple stupid check on the errors. Or find a way to touch all inputs from here.

    // For the time being to circumvent the fact that I can shamelessly submit the form after adding
    // a new recipient I am initialising the new recipients with errors, however if you do that the
    // recipient card will display "correct the highlighted fields" without having highlighted fields
    // (because they're still rightfully untouched) so probably a little bit of re-wording
    // on the error message is needed, or have a logic for touched/untouched cards.

    // Honestly, I've changed the error check logic 200 times because it felt flaky, I am 38 hours into this
    // without Redux and it is still Thursday afternoon. Draw your conclusions.
    props.saveAndNextStep();
  };

  const handleCopyMessage = (id) => {
    const { recipients } = props.giftData;
    const currentIndex = recipients.findIndex(r => r.id === id);
    let index = currentIndex - 1;
    let message = false;
    let fromName = false;
    let immediateDelivery = null;
    let scheduledSendTime = false;

    // Scan all previous recipients backwards until we find one with a message
    // We don't go further back to get sender name or schedule, since it could
    // all get out of sync.
    while (message === false && index >= 0) {
      const testRecipient = recipients[index];

      const {
        message: testMessage,
        fromName: testFromName,
        immediateDelivery: testImmediateDelivery,
        scheduledSendTime: testScheduledSendTime,
      } = testRecipient;

      if (testMessage) {
        message = testMessage;
      }
      if (testFromName) {
        fromName = testFromName;
      }
      if (typeof testImmediateDelivery === 'boolean') {
        immediateDelivery = testImmediateDelivery;
      }
      if (testScheduledSendTime) {
        scheduledSendTime = testScheduledSendTime;
      }

      index--;
    }

    // Update the data if we have it (individual conditionals to avoid clobbering)
    if (message) {
      props.updateGiftDataByRecipientId(id, { message });
    }
    if (fromName) {
      props.updateGiftDataByRecipientId(id, { fromName });
    }
    if (typeof immediateDelivery === 'boolean') {
      props.updateGiftDataByRecipientId(id, { immediateDelivery });
    }
    if (scheduledSendTime) {
      props.updateGiftDataByRecipientId(id, { scheduledSendTime });
    }

    // Increment to force a repaint. DIRTY.
    setIteration(iteration + 1);
  };

  return (<>
    {
      recipients.map((recipient, index) => {
        const isValid = validateRecipient(recipient);
        const isFormValid = validateRecipientForm(recipient);
        const isMainRecipient = (index === 0);
        const isOnlyRecipient = (recipients.length === 1);
        return (
          <Card className={`Personalise__card ${isFormValid ? 'valid' : 'error'}`} key={recipient.id} id={`recipient-details-${recipient.id}`}>

            {!isOnlyRecipient && RemoveButton(index)}

            <Collapse in={activeIndex !== index}>
              <Card.Header onClick={() => { updateActiveIndex(index); }}>
                <Row className="w-100">

                  {!isValid &&
                    <div className="col-12">
                      <p className="lead text-danger">Please edit this card to correct the indicated problems{!isOnlyRecipient && ' (or delete this card)'}</p>
                    </div>
                  }
                  <div className="col-xs-12 col-md-6">
                    <Card.Title>Card for</Card.Title>
                    <Card.Subtitle>{recipient.recipientName || <em className="text-danger">Missing recipient name</em>}</Card.Subtitle>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <Card.Title>Address</Card.Title>
                    <Card.Subtitle>{recipient.recipientEmail || <em className="text-danger">Missing recipient address</em>}</Card.Subtitle>
                  </div>
                </Row>
              </Card.Header>
            </Collapse>

            <Collapse in={activeIndex === index}>
              <Card.Body>
                <Row className="justify-content-center">
                  <Col xs={12} md={5}>
                    <Product
                      mode="preview"
                      product={props.product}
                      giftData={props.giftData.recipients[index]}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <RecipientFormSingle
                      {...props}
                      recipient={recipient}
                      iteration={iteration.toString()}
                      mainRecipient={isMainRecipient}
                      onlyRecipient={isOnlyRecipient}
                      handleCopyMessage={handleCopyMessage}
                    />
                    {!isFormValid && <p className="lead text-danger mt-4">Please correct the indicated problems{!isOnlyRecipient && ', or delete this card'}</p>}
                  </Col>
                </Row>
              </Card.Body>
            </Collapse>

          </Card>
        );
      })
    }

    <div className="text-center text-md-right">
      <div className="d-inline-flex flex-column">
        {
          recipients.length < maxRecipients &&
          <button className="btn btn-outline-primary mt-4" onClick={handleAddRecipient}>
            Add another recipient
          </button>
        }
        <button className="btn btn-primary mt-4" disabled={!validateAllRecipients(props.giftData.recipients)} onClick={handleSaveAndNextStep}>Finish adding recipients</button>
      </div>
    </div>

  </>);
}

GiftFormPersonalise.propTypes = {
  product: propTypes.object,
  giftData: propTypes.object,
  orderData: propTypes.object,
  updateGiftData: propTypes.func,
  updateGiftDataByRecipientId: propTypes.func,
  updateOrderData: propTypes.func,
  addRecipient: propTypes.func,
  removeRecipient: propTypes.func,
  handleGiftInputChange: propTypes.func,
  handleOrderInputChange: propTypes.func,
  saveAndNextStep: propTypes.func,
};
