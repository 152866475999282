import React from 'react';
import propTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

import siteSettings from '../../config/settings';

const CTA = ({ target, label }) => {

  const isExternal = /^https?:\/\//.test(target);

  if (isExternal) {
    return <a className="btn btn-primary" href={target}>{label}</a>;
  }

  return <Link to={target} className="btn btn-primary">{label}</Link>;

};

const StandardPanel = ({ data, panelIndex }) => {
  return <div className="StandardPanel">

    <Container>
      <Row className={`justify-content-center ${panelIndex % 2 ? 'flex-row-reverse' : ''}`}>

        {data.image &&
          <Col xs={12} md={6} xl={5} className="d-flex flex-column justify-content-center">
            <img src={siteSettings.endpoints.content + data.image} alt=""/>
          </Col>
        }

        <Col xs={12} md={6} xl={5} className="d-flex flex-column justify-content-center">
          {
            data.title &&
            <h3>{data.title}</h3>
          }
          {
            data.text &&
            <ReactMarkdown source={data.text} />
          }
          {
            (data.ctaLabel && data.ctaTarget) &&
            <p className="mt-4">
              <CTA target={data.ctaTarget} label={data.ctaLabel} />
            </p>
          }
        </Col>

      </Row>
    </Container>

  </div>;
};

StandardPanel.propTypes = {
  data: propTypes.object,
  panelIndex: propTypes.number,
};

export default StandardPanel;
