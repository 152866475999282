// Fairly manual checks here. Ideally we'd use form structure to provide the validation
// requirements (e.g. required, input type, pattern, etc.) and keep it all in one place.

// ...But it's simple enough to do manually, and we don't have a reference to the form,
// so we do what we can with just the individual recipient's data.

import { nameRegex, emailRegex } from './regex-patterns';

const validationPoints = [
  {
    key: 'recipientName',
    regex: nameRegex,
  },
  {
    key: 'recipientEmail',
    regex: emailRegex,
  },
  {
    key: 'message',
    regex: /.+/, // Basically "required"
  },
  {
    key: 'fromName',
    regex: nameRegex,
  },
];

export const validateRecipient = (data) => {
  // Returns a boolean true only if all tests complete successfully

  for (let i = 0; i < validationPoints.length; i++) {
    const key = validationPoints[i].key;
    const value = data[key] || '';
    const regex = validationPoints[i].regex;

    if (!regex.test(value)) {
      return false;
    }

  }

  // We've passed all tests
  return true;
};


export const validateRecipientForm = (data) => {
  // Returns a boolean true if all "non-pristine" tests complete successfully

  for (let i = 0; i < validationPoints.length; i++) {
    const key = validationPoints[i].key;
    const isPristine = data.formState[key].pristine;
    const value = data[key] || '';
    const regex = validationPoints[i].regex;

    if (!isPristine && !regex.test(value)) {
      return false;
    }

  }

  // We've passed all tests
  return true;
};


export const validateAllRecipients = (dataArray = []) => {
  if (dataArray.length === 0) {
    return false;
  }
  const recipientsWithErrors = dataArray.filter( x => !validateRecipient(x));
  return !(recipientsWithErrors.length);
};


export const getValidationPointNames = () => {
  return validationPoints.map(point => point.key);
};


export default {
  getValidationPointNames,
  validateRecipient,
  validateRecipientForm,
  validateAllRecipients,
};
