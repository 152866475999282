import React from 'react';
import propTypes from 'prop-types';
import { Container, Row, Col, Image } from 'react-bootstrap';

import siteSettings from '../config/settings';

class CategoryBanner extends React.Component {

  static get propTypes() {
    return {
      category: propTypes.object.isRequired,
    };
  }

  render() {
    const assets = this.props.category.assets || {};
    const backgroundColor = this.props.category.color || siteSettings.theme.backgroundColor;
    const backgroundImage = assets.banner ? 'url(' + siteSettings.endpoints.content + assets.banner + ')' : null;

    const componentClassList = ['CategoryBanner'];

    if (backgroundImage === null) {
      componentClassList.push('default-decoration');
    }

    return (
      <section className={ componentClassList.join(' ') } style={{
        backgroundColor,
        backgroundImage,
      }}>
        <Container>
          <Row className="justify-content-center align-items-center">
            {
              assets.image &&
              <Col sm={10} md={4} className="py-4">
                <Image
                  src={ siteSettings.endpoints.content + assets.image }
                  fluid
                  roundedCircle
                />
              </Col>
            }
            <Col sm={10} md={8} className="text-center py-4">
              <div className="text-block">
                <h1>{
                  (this.props.category && this.props.category.name) || 'Error: Category name not found'}
                </h1>
                <p>
                  {this.props.category.description}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

}

export default CategoryBanner;
