import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import Editable from '../Editable';

import siteSettings from '../../config/settings';

import Input from '../Form/Input';
import Delivery from '../Form/Delivery';
import Textarea from '../Form/Textarea';

import { nameRegex, emailRegex, namePattern, emailPattern } from '../../utilities/regex-patterns';

const formatDate = (date) => {
  let dateOptions = { month: 'long', day: 'numeric', year: 'numeric' };
  let timeOptions = { hour: 'numeric', minute: '2-digit', hourCycle: 'h12' };
  return `${date.toLocaleTimeString('en-GB', timeOptions)} on ${date.toLocaleDateString('en-GB', dateOptions)}`;
};

export default function RecipientEditableFormSingle(props) {
  const { recipient } = props;

  let datePlaceholder;
  recipient.scheduledSendTime = recipient.scheduledSendTime || new Date();

  // The default is immediate delivery
  if (recipient.immediateDelivery || recipient.immediateDelivery === undefined) {
    datePlaceholder = 'Immediate';
  } else {
    let dateString;
    typeof recipient.scheduledSendTime === 'object' ?
      dateString = formatDate(recipient.scheduledSendTime) :
      typeof recipient.scheduledSendTime === 'string' ?
        dateString = formatDate(new Date(recipient.scheduledSendTime)) :
        dateString = formatDate(new Date());
    datePlaceholder = 'Scheduled for ' + dateString;
  }

  // 🚨 Duplicated code from RecipientFormSingle.js 🚨
  const handleDeliveryChange = (event) => {
    if (event) {
      if (typeof event.currentTarget !== 'undefined') {
        const value = (event.currentTarget.value === 'yes');
        props.updateGiftDataByRecipientId(recipient.id, { immediateDelivery: value, scheduledSendTime: undefined });
      } else {
        props.updateGiftDataByRecipientId(recipient.id, { immediateDelivery: false, scheduledSendTime: event });
      }
    }
  };

  const handleInputChange = (event) => {
    console.log('handleInputChange', event);
    props.handleGiftInputChange(event, recipient.id);
  };

  const handleError = (field, error) => {
    const { id, errors: prevErrors } = recipient;
    const errors = { ...prevErrors, [field]: error };

    props.updateGiftDataByRecipientId(id, { errors });
  };

  // I'd laugh my ass off if at 5pm on a Thursday I find that this project had Redux all along.


  return (
    <>
      <Form.Group>
        <Editable
          label="Sending to"
          autoFocus
          summary={recipient.recipientName}
          type="input"
          validateFunction={() => nameRegex.test(recipient.recipientName)}
        >
          <Input
            required
            controlId={`recipientName-${recipient.id}`}
            type="text"
            pattern={namePattern}
            defaultValue={recipient.recipientName}
            onChange={handleInputChange}
            onError={handleError}
            validation={value => nameRegex.test(value)}
            invalidText="Please enter a valid recipient name. Symbols are not allowed."
          />
        </Editable>
      </Form.Group>

      <Form.Group>
        <Editable
          label="Recipient email"
          summary={recipient.recipientEmail}
          type="input"
          validateFunction={() => emailRegex.test(recipient.recipientEmail)}
        >
          <Input
            required
            controlId={`recipientEmail-${recipient.id}`}
            type="email"
            pattern={emailPattern}
            defaultValue={recipient.recipientEmail}
            onChange={handleInputChange}
            onError={handleError}
            validation={value => emailRegex.test(value)}
            invalidText="Please enter a valid email address for this recipient."
          />
        </Editable>
      </Form.Group>

      <Form.Group>
        <Editable
          label="Card message"
          summary={recipient.message}
          type="input"
        >
          <Textarea
            required
            controlId={`message-${recipient.id}`}
            type="textarea"
            placeholder="Dear Friend..."
            defaultValue={recipient.message}
            onChange={handleInputChange}
            onError={handleError}
            invalidText="Please enter a message."
            rows="3"
            maxLength={siteSettings.maxMessageLength}
          />
        </Editable>
      </Form.Group>

      <Form.Group>
        <Editable
          label="From name"
          summary={recipient.fromName}
          type="input"
          validateFunction={() => nameRegex.test(recipient.fromName)}
        >
          <Input
            required
            controlId={`fromName-${recipient.id}`}
            type="text"
            pattern={namePattern}
            defaultValue={recipient.fromName}
            onChange={handleInputChange}
            onError={handleError}
            validation={value => nameRegex.test(value)}
            invalidText="Please enter a valid sender name. Symbols are not allowed."
          />
        </Editable>
      </Form.Group>

      <Form.Group>
        <Editable
          label="Delivery"
          summary={datePlaceholder}
          type="input"
          keepOpenOnBlur={true}
        >
          <Delivery
            controlId={`Delivery-${recipient.id}`}
            onChange={handleDeliveryChange}
            immediateDelivery={recipient.immediateDelivery}
            scheduledSendTime={recipient.scheduledSendTime}
            skipLabel={true}
          />
        </Editable>
      </Form.Group>
    </>
  );
}

RecipientEditableFormSingle.propTypes = {
  recipient: propTypes.object,
  product: propTypes.object,
  giftData: propTypes.object,
  orderData: propTypes.object,
  updateGiftDataByRecipientId: propTypes.func,
  updateOrderData: propTypes.func,
  handleGiftInputChange: propTypes.func,
  handleOrderInputChange: propTypes.func,
  mainRecipient: propTypes.bool,
};
