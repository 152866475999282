import React from 'react';
import propTypes from 'prop-types';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Only ever rendered by a <Product mode="thumbnail" thumbnailLink={true} />
// component, and only when there's product data to render, so we can rely on
// the immediate presence of the 'product' prop object.

class ProductModal extends React.Component {

  static get propTypes() {
    return {
      product: propTypes.object,
      modalActive: propTypes.bool,
      contentEndpoint: propTypes.string,
      toggleModal: propTypes.func,
    };
  }

  // We want to start the personalisation process from step 1 (but we keep any
  // existing giftData to pre-populate fields for convenience)
  clearFormProgress () {
    sessionStorage.setItem('formStep', 0);
  }

  render () {
    return ( this.props.product &&
      <Modal show={ this.props.modalActive } onHide={ this.props.toggleModal } size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>{ this.props.product.name }</Modal.Header>
        <Modal.Body className="ProductModal">
          <Row>
            <Col xs={12} md={6}>
              <img
                className="image"
                src={
                  this.props.contentEndpoint +
                  this.props.product.assets.image
                }
                title={ this.props.product.description }
                alt={ this.props.product.description }
              />
            </Col>
            <Col xs={12} md={6} className="d-flex flex-column justify-content-between">
              <div>
                <div className="py-3 py-lg-1 checklist">
                  <p>&#10004; Eco-friendly paperless alternative</p>
                  <p>&#10004; Write a unique personal message</p>
                  <p>&#10004; Send to one person or to many</p>
                  <p>&#10004; Add an optional donation to charity</p>
                  <p>&#10004; Schedule or send immediately</p>
                </div>
              </div>
              <div>
                <Link to={ '/create/' + this.props.product.slug }>
                  <Button className="my-3" onClick={this.clearFormProgress}>Personalise and send</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

}

export default ProductModal;
