import React from 'react';
import propTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import LoadingPlaceholder from './LoadingPlaceholder';
import PageError from './PageError';

import { getPaymentProviders } from '../utilities/api';
import { displayInputAsCurrency } from '../utilities/currency';
import { ordinals } from '../utilities/strings';

import Paypal from './Payment/Paypal';
import ApplePay from './Payment/ApplePay';
import GooglePay from './Payment/GooglePay';
import Dummy from './Payment/Dummy';
import Braintree from './Payment/Braintree';

const paymentProviderMapping = {
  braintree: {
    name: 'UK credit/debit card',
    component: Braintree,
  },
  paypal: {
    name: 'PayPal',
    component: Paypal,
  },
  applepay: {
    name: 'Apple Pay',
    component: ApplePay,
  },
  googlepay: {
    name: 'Google Pay',
    component: GooglePay,
  },
  dummy: {
    name: 'Test payment',
    component: Dummy,
  },
};

class Payment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      status: 'loading',
      paymentProviders: [],
      totalValue: props.orderData.gifts.reduce((total, gift) => {
        // TODO: This can be simplified once we populate orderData.gifts correctly and always have
        // a numeric value in "gift.value" (see GiftForm/SenderDetailsAndPayment)
        const value = gift.value === 'other' ? parseFloat(gift.otherValue) || 0 : parseFloat(gift.value) || 0;
        return total + value;
      }, 0),
    };
    this._isMounted = false;

    this.createPaymentMethodSetter = this.createPaymentMethodSetter.bind(this);
  }

  static get propTypes() {
    return {
      orderData: propTypes.object,
      giftData: propTypes.object,
      updateOrderData: propTypes.func,
      saveAndNextStep: propTypes.func,
      goToNextStep: propTypes.func,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.state.totalValue) {
      getPaymentProviders()
        .then( (value) => {
          this.setState({
            paymentProviders: value,
            status: 'okay',
          });
          // Set the first option as active if we don't have a valid
          // selection within props.orderData
          if (value.indexOf(this.props.orderData.paymentMethod) === -1) {
            this.createPaymentMethodSetter(value[0])();
          }
        });
    } else {
      this.setState({ status: 'okay' });
    }
    return;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createPaymentMethodSetter = paymentMethod => (e) => {
    e && e.preventDefault();
    this.props.updateOrderData({ paymentMethod });
  };

  render() {
    // If there's no donation we just show a submit button
    if (!this.state.totalValue) {
      return (
        <Row className="justify-content-center">
          <Col xs={12} sm={8} lg={6}>
            <button className="btn btn-block btn-primary mt-4" type="submit">
              Send {this.props.giftData.recipients.length > 1 ? ordinals[this.props.giftData.recipients.length] + ' cards' : 'your card'}
            </button>
          </Col>
        </Row>
      );
    }

    const PaymentComponent = this.props.orderData && this.props.orderData.paymentMethod && paymentProviderMapping[this.props.orderData.paymentMethod] && paymentProviderMapping[this.props.orderData.paymentMethod].component;

    // Present the available payment options
    return (<>
      <div className="Payment">

        <h2 className="my-2">Payment<span><img className="icon mx-3" src="../images/password.svg" alt="lock icon"></img></span></h2>

        <p>
          Your donation of <strong>{displayInputAsCurrency(this.state.totalValue, true, true, true, true)}</strong>
          {this.props.orderData.giftAid && ` will be worth ${displayInputAsCurrency(this.state.totalValue * 1.25, true, true, true, true)} to us thanks to Gift Aid.`}
        </p>

        { this.state.status === 'loading' && <LoadingPlaceholder /> }

        { this.state.status === 'error' && <PageError /> }

        { this.state.status === 'okay' && <>
          <div className="payment-options">
            { this.state.paymentProviders.map((provider, index) => {
              // SDKs not loaded or device does not support
              // this specific provider.
              // Hide selector and stop rendering the component.

              // Block non-applepay enabled devices.
              if (
                provider === 'applepay' &&
                (!window.ApplePaySession ||
                !window.ApplePaySession.supportsVersion(3) ||
                !window.ApplePaySession.canMakePayments())
              ) {
                return null;
              }

              // Block non-gpay enabled devices.
              // and if sdk is not loaded.
              if (
                provider === 'googlepay' &&
                !window.google &&
                !window.PaymentRequest
              ) {
                return null;
              }

              return (
                <button key={index} id={provider} className={`btn payment-toggle payment-toggle-${provider} payment-toggle-${provider === this.props.orderData.paymentMethod ? 'active' : 'inactive'}`} onClick={this.createPaymentMethodSetter(provider)}>
                  {paymentProviderMapping[provider].name}
                </button>
              );
            })}
          </div>

          { PaymentComponent &&
            <PaymentComponent
              {...this.props}
              totalValue={this.state.totalValue}
              orderData={this.props.orderData}
              giftData={this.props.giftData}
            />
          }
        </>}

      </div>
    </>);
  }

}

export default Payment;
