import React from 'react';
import propTypes from 'prop-types';
// @todo please look if https://github.com/nathanstitt/react-braintree-fields/pull/60
// has been merged and published on the original package and revert this import back.
import { Braintree, HostedField } from '@deimosindustries/react-braintree-fields';
import { Form, Row, Col } from 'react-bootstrap';

import LoadingPlaceholder from '../LoadingPlaceholder';

import { getPaymentProviderToken, submitOrder } from '../../utilities/api';
import { generateOrderSubmissionData } from '../../utilities/transformations';
import { ordinals } from '../../utilities/strings';

// Remap "techy" Braintree error messages to something more feasible
const BRAINTREE_CUSTOMER_MSG = {
  HOSTED_FIELDS_FIELDS_EMPTY: 'Some required information is missing. Please make sure you\'ve filled out the whole form.',
  HOSTED_FIELDS_FIELDS_INVALID: 'Some of the payment fields are invalid, please double check and try again.',
  HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE: 'This payment form is no longer valid, try refreshing the page.',
  HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED: 'The CVV seems to be wrong or invalid',
  HOSTED_FIELDS_FAILED_TOKENIZATION: 'Tokenization failed server side. Is the card valid?',
  HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR: 'We had some issues while connecting to your bank or card issuer, please try again later.',
  LIABILITY_NOT_SHIFTED: 'We need your authorisation for processing this card. Please follow the instructions from your bank and try again.',
  GENERIC: 'There was an error while processing your payment, please check the details and try again.',
};

class PaymentBraintree extends React.Component {

  constructor(props) {
    super(props);
    this.numberField = React.createRef();
    this.braintree = React.createRef();
    [
      'onError',
      'onAuthorizationSuccess',
      'onCardTypeChange',
      'onValidityChange',
      'requestPayment',
    ].forEach(prop => (this[prop] = this[prop].bind(this)));

    this.state = {
      buttonEnabled: true,
      paymentMethod: null,
      status: 'loading',
      isBraintreeReady: false,
      authorization: null,
      payloadNonce: null,
      checkoutMessage: null,
      giftValue: parseFloat(props.orderData.donationAmount === 'other' ? props.orderData.otherDonationAmount || 0 : props.orderData.donationAmount || 0),
    };

    this.threedclient = false;

    getPaymentProviderToken('braintree')
      .then( (value) => {
        if (value.success !== true) {
          throw new Error('Braintree payment failed to get client token', value);
        }
        this.setState({
          status: 'okay',
          authorization: value.clientToken,
        });
      })
      .catch( (error) => {
        this.setState({
          status: 'error',
          authorization: null,
        });
        console.error(error);
      } );
  }

  static get propTypes() {
    return {
      orderData: propTypes.object,
      giftData: propTypes.object,
      saveAndNextStep: propTypes.func,
      onOrderDataChange: propTypes.func,
      goToNextStep: propTypes.func,
      updateOrderCompletionData: propTypes.func,
      totalValue: propTypes.number,
    };
  }

  onError(error) {
    console.error(error);
    this.setState({ status: 'error' });
  }

  setCustomerErrorMessage( response ) {
    // Set remapped message
    const checkoutMessage = BRAINTREE_CUSTOMER_MSG[response.code] || BRAINTREE_CUSTOMER_MSG['GENERIC'];
    this.setState({ checkoutMessage });

    // Highlight wrong fields
    if (response.details && response.details.invalidFields) {
      const { invalidFieldKeys: keys, invalidFields: fields } = response.details;
      keys.forEach((key) => {
        fields[key].classList.add('is-invalid');
      });
    }
  }

  requestPayment( e ) {
    e.preventDefault();
    this.setState({ buttonEnabled: false });
    this.setState({ checkoutMessage: null });

    // Validate the sender details form
    let form = document.getElementById('sender-details-form');
    if (form.length && form.checkValidity() === false) {
      if (form.reportValidity) {
        form.reportValidity();
        this.setState({ buttonEnabled: true });
        return;
      }
    }
    // If we get past this point the form appears valid (client-side), so we continue

    this.tokenize()
      .then((token) => {
        const order = generateOrderSubmissionData(this.props.orderData, this.props.giftData);
        return this.threedclient.verifyCard({
          onLookupComplete: (data, next) => {
            // Pass to the next step
            next();
          },
          amount: this.props.totalValue,
          nonce: token.nonce,
          bin: token.details.bin,
          email: order.user.email,
          billingAddress: {
            givenName: order.user.firstName,
            surname: order.user.lastName,
          },
        });
      })
      .then((token) => {
        if (typeof token.liabilityShifted !== 'undefined' && token.liabilityShifted === false) {
          // eslint-disable-next-line no-throw-literal
          throw { code: 'LIABILITY_NOT_SHIFTED' };
        }

        this.setState({ token, error: null });

        const order = generateOrderSubmissionData(this.props.orderData, this.props.giftData);

        const customer = order.user;

        const transaction = {
          processor: 'braintree',
          paymentMethodNonce: token.nonce,
          amount: this.props.totalValue,
          customer,
        };

        submitOrder(order, transaction)
          .then( (response) => {
            if (response.success) {
              this.props.updateOrderCompletionData(response);
              this.props.goToNextStep();
            } else {
              this.setCustomerErrorMessage(response);
              this.setState({ buttonEnabled: true });
            }
          });
      },
      ).catch(
        (error) => {
          this.setCustomerErrorMessage(error);
          this.setState({
            token: null,
            error,
          });
          this.setState({ buttonEnabled: true });
        },
      );
  }

  onValidityChange( e ){
    let field = e.fields[e.emittedBy];
    let containerElement = field.container;

    if (field.isValid) {
      containerElement.classList.remove('is-invalid');
      containerElement.classList.add('is-valid');
    } else {
      containerElement.classList.remove('is-valid');
      containerElement.classList.add('is-invalid');
    }
  }

  onCardTypeChange({ cards }) {
    if (1 === cards.length) {
      const [card] = cards;

      this.setState({ card: card.type });

      if (card.code && card.code.name) {
        this.cvvField.setPlaceholder(card.code.name);
      } else {
        this.cvvField.setPlaceholder('CVV');
      }
    } else {
      this.setState({ card: '' });
      this.cvvField.setPlaceholder('CVV');
    }
  }

  onAuthorizationSuccess() {
    this.setState({ isBraintreeReady: true });
  }

  render() {
    let nameOnCardDefault = '';
    if (typeof this.props.orderData.senderFirstName !== 'undefined' && typeof this.props.orderData.senderLastName !== 'undefined') {
      nameOnCardDefault = this.props.orderData.senderFirstName + ' ' + this.props.orderData.senderLastName;
    }

    let cardTypeImg, cardTypeSrc = '';
    if (typeof this.state.card !== 'undefined' && this.state.card !== ''){
      cardTypeSrc = `/images/cards/${this.state.card}.png`;
      cardTypeImg = <img src= {cardTypeSrc} alt="Credit Card logo" className="paymentIcon" />;
    }

    // We have to render the <Braintree> form so that the hosted fields setup process can find them in the DOM
    // ...But we don't have to SHOW the <Braintree> form until it's ready
    const hiddenStyle = {
      height: 0,
      overflow: 'hidden',
    };

    return (<>
      { (this.state.status === 'loading' || !this.state.isBraintreeReady ) && <LoadingPlaceholder /> }

      <div className="Payment PaymentBraintree" style={ this.state.isBraintreeReady ? null : hiddenStyle }>
        <Braintree
          className={ this.state.isBraintreeReady ? '' : 'disabled' }
          ref={this.braintree}
          authorization={this.state.authorization}
          onAuthorizationSuccess={this.onAuthorizationSuccess}
          onThreeDSecureReady={(err, client) => this.threedclient = client}
          onError={this.onError}
          getTokenRef={t => (this.tokenize = t)}
          onCardTypeChange={this.onCardTypeChange}
          onValidityChange={this.onValidityChange}
          styles={{
            input: {
              height: 'auto',
              width: 'auto',
              border: '1px solid #232f4a',
              color: '#232f4a',
              'background-color': '#f7f7fa',
            },
            ':focus': {
              color: '#232f4a',
            },
          }}
        >
          <Row>
            <Col md={12}>
              <Form.Group controlId="Cardholder">
                <Form.Label>Name on Card:*</Form.Label>
                <Form.Control required type="text" placeholder="Name on Card" defaultValue={nameOnCardDefault} onChange={this.handleInputChange} />
                <Form.Control.Feedback type="invalid">Please enter your name as shown on payment card.</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="Cardnumber">
                <Form.Label>Card Number:</Form.Label>
                <HostedField
                  type="number"
                  ref={this.numberField}
                  message="Invalid card number"
                />
                <span className="card-type-indicator">{cardTypeImg}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="Cardexpiry">
                <Form.Label>Expiry Date:</Form.Label>
                <HostedField type="expirationDate" placeholder="MM/YYYY" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="Cardcvv">
                <Form.Label>CVV:</Form.Label>
                <HostedField type="cvv" placeholder="CVV" ref={(cvvField) => { this.cvvField = cvvField; }} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="Cardpostcode">
                <Form.Label>Billing Postcode:</Form.Label>
                <HostedField type="postalCode" />
              </Form.Group>
            </Col>
          </Row>
        </Braintree>

        { this.state.checkoutMessage ? <p className="alert alert-warning">{this.state.checkoutMessage}</p> : null }

        <Row className="justify-content-center my-4">
          <Col xs={12} sm={8} lg={6}>
            <button
              className="btn btn-block btn-primary my-4"
              id="braintree-hosted-fields-submit"
              onClick={this.requestPayment}
              disabled={!this.state.buttonEnabled}
            >
              Send {this.props.giftData.recipients.length > 1 ? ordinals[this.props.giftData.recipients.length] + ' cards' : 'your card'}
            </button>
          </Col>
        </Row>

      </div>
    </>);
  }

}

export default PaymentBraintree;
