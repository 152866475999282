import { retrieveFoeData } from '../utilities/foe-data';

export const generateOrderSubmissionData = (orderData, giftData) => {
  // Build the JSON object for the complete order submission
  // TODO: Just store it in the required format as we collect it!

  const foeData = retrieveFoeData();

  const data = {
    user: {
      firstName: orderData.senderFirstName,
      lastName: orderData.senderLastName,
      email: orderData.senderEmail,
    },
    consent: {
      dpStatement: 'By signing up for communications you consent to us further contacting you about our campaigns and how you can get involved, including whether you can help with a donation.',
      email: orderData.consentEmail,
      post: orderData.consentPost,
      phone: orderData.consentPhone,
    },
    // transaction: props.transactionData,
    giftAid: {
      giftAidOptIn: !!orderData.giftAid,
      // TODO: Load giftAidStatement dynamically / look at using a statement ID instead
      giftAidStatement: 'I am a UK taxpayer. I understand that if I pay less Income Tax and/or Capital Gains Tax in the current tax year than the amount of Gift Aid claimed on my eligible donations I must pay the difference. I want to Gift Aid this donation and any eligible donations I make in the future or have made in the past 4 years to Friends of the Earth Charitable Trust.',
      giftAidAddress: {
        addressLine1: orderData.giftAidAddressLine1,
        addressLine2: orderData.giftAidAddressLine2,
        city: orderData.giftAidAddressCity,
        postcode: orderData.giftAidAddressPostcode,
      },
    },
    acquisition: {
      sourceCode: foeData.source,
      analytics: {
        source: foeData.analytics.utm_source,
        medium: foeData.analytics.utm_medium,
        campaign: foeData.analytics.utm_campaign,
        content: foeData.analytics.utm_content,
        term: foeData.analytics.utm_term,
      },
    },
    gifts: giftData.recipients.map( (recipient) => {

      // Product ID is currently set for the whole order, not per gift
      const productId = giftData.productId;

      // Gift value is currently set for the whole order, not per-gift
      const orderValue = parseFloat(giftData.value === 'other' ? giftData.otherValue || 0 : giftData.value || 0);

      // Value-display choice is currently set for the whole order, not per gift
      const valueDisplay = giftData.valueDisplay;

      // Date is only saved on change so if the default is left, we just use the current date/time
      const scheduledSendTime = recipient.immediateDelivery ? new Date() :
        recipient.scheduledSendTime ? new Date(recipient.scheduledSendTime) : new Date();

      return {
        productId,
        value: orderValue / giftData.recipients.length,
        valueForDisplay: (giftData.recipients.length > 1) ? orderValue : null,
        valueDisplay,
        scheduledSendTime,
        // productName: recipient.productName,
        // productSlug: recipient.productSlug,
        recipient: {
          name: recipient.recipientName,
          addressType: 'email',
          address: recipient.recipientEmail,
        },
        fromName: recipient.fromName,
        message: recipient.message,
      };
    }),
  };

  return data;
};

export default {
  generateOrderSubmissionData,
};
