const currencyPrefix = '£';
const currencyPostfix = '';
const currencyDecimalSymbol = '.';
const currencyDecimalPlaces = 2;

export const displayInputAsCurrency = (value, includeCurrencyPrefix = true, includeCurrencyPostfix = true, forceLeadingZero = true, forceFixedDecimals = false) => {
  // Has to allow for partial user entry of decimals during typing
  // (e.g. can't use toFixed() to enforce number of decimal places)

  // Get rid of any non-numeric characters
  value = value
    .toString()
    .replace(/[^\d.]/g, '');

  if (parseFloat(value)) {
    // Get rid of any leading zeros
    value = value
      .replace(/^0+/, '');

    // Get rid of a second decimal symbol (and anything after it)
    value = value
      .replace(new RegExp('(^[\\d]+(?:\\' + currencyDecimalSymbol + '\\d?\\d?)?).*'), '$1');

    // Process decimals
    if (forceFixedDecimals) {
      // Round, or pad with zeros to fill out missing decimal places (if requested, shouldn't be used on inputs)
      const parts = value.split(currencyDecimalSymbol);
      value = parts[0] + currencyDecimalSymbol + ((parts[1] || '') + '0'.repeat(currencyDecimalPlaces));
      value = parseFloat(value).toFixed(currencyDecimalPlaces);
    }
  }

  // Put a leading zero back if requested (and we have a decimal)
  if (forceLeadingZero && value.indexOf(currencyDecimalSymbol) !== -1) {
    value = value
      .replace(/^\./, '0.')
      .replace(/^$/, '0');
  }

  // Return with or without prefixes, as requested
  return `${includeCurrencyPrefix ? currencyPrefix : ''}${value}${includeCurrencyPostfix ? currencyPostfix : ''}`;
};

export default {
  displayInputAsCurrency,
};
