import React from 'react';
import propTypes from 'prop-types';

import LoadingPlaceholder from './LoadingPlaceholder';
import Error from './Error';

import AnimationCard from './AnimationCard';

const components = {
  AnimationCard,
};

export default function Animation({ status, product, gift, config }) {
  if (status === 'loading') {
    return <LoadingPlaceholder />;
  }

  if (status === 'error') {
    return <Error />;
  }

  if (product && gift) {
    const type = product.type || 'card'; // TODO: Hardcoded default, need to ensure DB storage of this
    const animationName = 'Animation' + type.substring(0, 1).toUpperCase() + type.substr(1);
    const AnimationComponent = components[animationName];
    return (
      <div className="Animation">
        <AnimationComponent
          product={product}
          gift={gift}
          config={config}
        />
      </div>
    );
  }

  // We're somehow not 'loading', not 'error', and yet don't have a product and gift. PANIC.
  return <Error />;
}

Animation.propTypes = {
  status: propTypes.string,
  product: propTypes.object,
  gift: propTypes.object,
  config: propTypes.object,
};
