import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const characterWarningThreshold = 30;
export default function Textarea(props) {
  const {
    controlId,
    required = false,
    label,
    help,
    invalidText = 'Please input a value here',
    defaultValue = '',
    type,
    maxLength,
    rows,
    placeholder,
    validation = undefined,
    pristine = true,
    onChange,
    onError = undefined,
  } = props;

  const [messageCharsRemaining, setMessageCharsRemaining] = useState( maxLength - defaultValue.length );
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);
  // Because we update it externally
  const [message, setMessage] = useState(defaultValue);

  // On first render check the starting value if we have one
  // hinthint: localstorage
  useEffect(() => {
    if (message && message.length > 0) {
      handleValidation(message);
    }
  }, []);

  // On use
  useEffect(() => {
    // Report back to the parent form that we touched
    // the input and has no errors.
    const [id] = controlId.split('-');
    onError && onError(id, (error && touched));
    // Do you have a minute to talk about our lord and saviour Redux?
  }, [error, touched]);


  const handleValidation = (value) => {
    // Run the custom validation
    // And stupid check for required inputs if there's no custom validation
    if (typeof validation === 'function') {
      const valid = validation(value);
      setError(!valid);
    } else if (required && value.length === 0) {
      setError(true);
      return;
    } else {
      setError(false);
    }
  };

  const handleBlur = (event) => {
    if (!touched) {
      setTouched(true);
    }

    handleValidation(event.currentTarget.value);
  };

  const handleChange = (event) => {
    setMessage(event.currentTarget.value);
    onChange(event);
    if (!touched) {
      setTouched(true);
    }

    handleKeyUp(event);
    handleValidation(event.currentTarget.value);
  };

  const handleKeyUp = (event) => {
    const currentText = event.target.value;
    const characterCount = currentText.length;
    const remainingChars = Math.max(0, maxLength - characterCount);
    setMessageCharsRemaining(remainingChars);
  };

  const HelpElement = () => help ? <p className="small">{help}</p> : null;
  const FeedbackElement = () => (error && touched) ? <Form.Control.Feedback type="invalid">{invalidText}</Form.Control.Feedback> : null;
  const CounterElement = () => <p className="small text-right position-absolute w-100" style={{ transform: 'translateY(-1.5em)' }}><span className="remaining-chars">{messageCharsRemaining}</span> characters remaining</p>;

  return (
    <Form.Group controlId={controlId} className={'position-relative' + ( pristine ? '' : ' was-validated' )}>
      {label && <Form.Label>{label}{required && '*'}</Form.Label>}
      {messageCharsRemaining <= characterWarningThreshold && <CounterElement />}
      <Form.Control
        required={required}
        type={type}
        as="textarea"
        rows={rows}
        placeholder={placeholder}
        maxLength={maxLength}
        value={message}
        isInvalid={(error && touched)}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        className="pb-4"
      />
      <FeedbackElement />
      <HelpElement />
    </Form.Group>
  );
}

Textarea.propTypes = {
  controlId: propTypes.string,
  required: propTypes.bool,
  label: propTypes.string,
  pattern: propTypes.string,
  help: propTypes.string,
  maxLength: propTypes.number,
  rows: propTypes.string,
  placeholder: propTypes.string,
  invalidText: propTypes.string,
  defaultValue: propTypes.string,
  type: propTypes.string,
  validation: propTypes.func,
  onChange: propTypes.func,
  onCopyMessage: propTypes.func,
  onError: propTypes.func,
};
