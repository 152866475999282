import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class MaintenancePage extends React.Component {

  render() {
    return (
      <section className="MaintenancePage">
        <Container>
          <Row className="align-items-center text-center" style={{ height: '100vh' }}>
            <Col>
              <h1>Site offline</h1>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

}

export default MaintenancePage;
