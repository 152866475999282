import React from 'react';
import propTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Scroll from 'react-scroll';

import GiftForm from './GiftForm';
import LoadingPlaceholder from './LoadingPlaceholder';
import PageError from './PageError';

import { getProductBySlug } from '../utilities/api';
import setWindowTitle from '../utilities/set-window-title';
import { getValidationPointNames } from '../utilities/validate-recipients';

const scroll = Scroll.animateScroll;

const formSteps = [
  'Personalise',
  'Donation',
  'Preview',
  'SenderDetailsAndPayment',
  'Process',
];

const helpSteps = [
  'Personalise and send up to 10 cards',
  'Add an optional donation',
  'Preview your gift',
  'Enter your details',
];

const createRecipientId = () => {
  // Very harsh but we id the new recipients by timestamp
  // Change that if you want with an uuid or some kind of better unique id generator
  // Ideally this would be the same id is shared with the one when the
  // recipient is stored on mongodb (if we ever store them)
  return Date.now();
};

const generateNewRecipient = () => {
  return {
    id: createRecipientId(),
    formState: {
      recipientName: {
        pristine: true,
        error: true,
      },
      recipientEmail: {
        pristine: true,
        error: true,
      },
      message: {
        pristine: true,
        error: true,
      },
      fromName: {
        pristine: true,
        error: true,
      },
    },
  };
};

class PageCreate extends React.Component {

  constructor (props) {
    super(props);

    // Pick up and clear server-side provided context
    this.context = props.staticContext;
    if (typeof window !== 'undefined' && window.__INITIAL_CONTEXT__) {
      this.context = window.__INITIAL_CONTEXT__;
      window.__INITIAL_CONTEXT__ = false;
    }

    // Set up basic starting state
    this.state = {
      product: null,
      status: 'loading',
      formStatus: 'loading',
      prefillData: {},
      giftData: {},
      orderData: {},
      orderCompletionData: {},
      formStep: 0,
    };

    // If we have product data from context the page can be rendered
    // (But not the form)
    if (this.context && this.context.data && this.context.data.product) {
      this.state.product = this.context.data.product;
      this.state.status = 'okay';
    }

    // If we're in the browser we might have sessionStorage to pick data up from
    // (And so we can display the form without risking a flash-of-unfilled-first-step.)
    if (typeof window !== 'undefined') {
      this.state.formStatus = 'okay';
      this.state.prefillData = sessionStorage.getItem('prefillData') ? JSON.parse(sessionStorage.getItem('prefillData')) : {};
      this.state.giftData = sessionStorage.getItem('giftData') ? JSON.parse(sessionStorage.getItem('giftData')) : {};
      this.state.orderData = sessionStorage.getItem('orderData') ? JSON.parse(sessionStorage.getItem('orderData')) : {};
      this.state.orderCompletionData = {};
      this.state.formStep = sessionStorage.getItem('formStep') ? JSON.parse(sessionStorage.getItem('formStep')) : 0;
    }

    // If we've pulled any recipient data from sessionStorage we should mark fields
    // as non-pristine so that any errors can be highlighted.
    if (this.state.giftData.recipients) {
      const keys = getValidationPointNames();
      this.state.giftData.recipients.forEach( (recipient) => {
        // markRecipientDataDirty() requires the component to be mounted so we
        // have to duplicate code a bit here.
        keys.forEach( (key) => {
          recipient.formState[key].pristine = false;
        });
      });

      // (If there's only a single recipient and it's 'empty', they can be flipped
      // back to pristine.)
      if (this.state.giftData.recipients?.length === 1) {
        const recipient = this.state.giftData.recipients[0];
        let recipientIsEmpty = true;
        keys.forEach( (key) => {
          if (recipient[key]) {
            recipientIsEmpty = false;
          }
        });
        keys.forEach( (key) => {
          recipient.formState[key].pristine = recipientIsEmpty;
        });
      }
    } else {
      // Otherwise initialise a fresh recipient list
      this.state.giftData.recipients = [generateNewRecipient()];
    }

    // Bind 'this' to local functions
    this.updateGiftData = this.updateGiftData.bind(this);
    this.updateGiftDataByRecipientId = this.updateGiftDataByRecipientId.bind(this);
    this.addRecipient = this.addRecipient.bind(this);
    this.removeRecipient = this.removeRecipient.bind(this);
    this.markRecipientDataDirty = this.markRecipientDataDirty.bind(this);
    this.updateOrderCompletionData = this.updateOrderCompletionData.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleFormStepChange = this.handleFormStepChange.bind(this);
    this.stepChangeScrollTarget = React.createRef();
    this._isMounted = false;
  }

  static get propTypes() {
    return {
      match: propTypes.object,
      staticContext: propTypes.object,
    };
  }

  updateOrderCompletionData (data) {
    this.setState({ orderCompletionData: data });
  }

  handleDataChange(datasetName, dataElement, callback) {
    let elementKey = Object.keys(dataElement)[0];
    let elementValue = dataElement[elementKey];

    this.setState(prevState => ({
      [datasetName]: {
        ...prevState[datasetName],
        [elementKey]: elementValue,
      },
    }),
    () => {
      // Callback to update persistent storage after making this change
      sessionStorage.setItem(datasetName, JSON.stringify(this.state[datasetName]));
      // Run the callback function if supplied
      typeof callback === 'function' && callback();
    });
  }

  handleFormStepChange(delta) {
    scroll.scrollTo(
      this.stepChangeScrollTarget.current.getBoundingClientRect().top + window.scrollY,
      {
        smooth: true,
        duration: 500,
      },
    );
    const nextStep = Math.max(0, Math.min(formSteps.length - 1, this.state.formStep + delta));
    sessionStorage.setItem('formStep', nextStep);
    this.setState({ formStep: nextStep });
  }

  updateGiftData (data) {
    this.getDataChangeHandler('giftData')(data);
  }

  updateGiftDataByRecipientId (id, data) {
    const { recipients } = this.state.giftData;
    const matchId = recipient => recipient.id === id;
    const target = recipients.find(matchId);
    const index = recipients.findIndex(matchId);

    // Update the recipient with the new data
    recipients[index] = { ...target, ...data };

    // Set 'pristine' to false for any updated form elements
    Object.keys(data).forEach( (key) => {
      recipients[index].formState[key] && (recipients[index].formState[key].pristine = false);
    });

    // Brute force check all recipient validity so we can pass a boolean prop
    // ...

    // Store it (both state and sessionStorage)
    this.getDataChangeHandler('giftData')({
      recipients,
    });
  }

  markRecipientDataDirty (index) {
    const { recipients } = this.state.giftData;
    const keys = getValidationPointNames();

    keys.forEach( (key) => {
      recipients[index].formState[key].pristine = false;
    });

    // Store it (both state and sessionStorage)
    this.getDataChangeHandler('giftData')({
      recipients,
    });
  }

  getDataChangeHandler (datasetName) {
    return (dataElement, callback) => {
      this.handleDataChange(datasetName, dataElement, callback);
    };
  }

  // This project _needs_ redux.
  async addRecipient () {
    return new Promise((resolve) => {
      const { recipients } = this.state.giftData;
      const newRecipient = generateNewRecipient();
      this.getDataChangeHandler('giftData')({
        recipients: [...recipients, newRecipient ],
      }, () => {
        resolve(this.state.giftData.recipients.length - 1);
      });
    });
  }

  removeRecipient (index) {
    const { recipients } = this.state.giftData;
    recipients.splice(index, 1);

    this.getDataChangeHandler('giftData')({
      recipients,
    });

    return recipients.length;
  }

  getFormStepUpdater (delta) {
    return () => this.handleFormStepChange(delta);
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.state.product) {
      getProductBySlug(this.props.match.params.productSlug)
        .then( (value) => {
          if (value === null) {
            throw new Error(`Product with slug "${this.props.match.params.productSlug}" not found`);
          }

          // Update the window title
          setWindowTitle(value.name);

          // Put the product's ID into giftData storage for later use
          this._isMounted && this.getDataChangeHandler('giftData')({
            productId: value.id || value._id,
          });

          // Populate the product object and allow component to fully render
          this._isMounted &&
          this.setState({
            product: value,
            status: 'okay',
          });

          // Put the product's details into the giftData storage for easier retrieval later
          // PS: this does not actually work, because handleDataChange only picks up the value changed at [0]
          // So we might need to call it one by one, or fix handleDataChange.
          // I leave this as is because I do not know the implications why it was done that way
          this._isMounted && this.getDataChangeHandler('giftData')({
            productId: value.id || value._id,
            productName: value.name,
            productSlug: value.slug,
          });
        })
        .catch( (error) => {
          this._isMounted &&
          this.setState({
            product: null,
            status: 'error',
          });
          console.error(error);
        } );
    } else {
      // Ensure we store the productId within the giftData
      this.getDataChangeHandler('giftData')({
        productId: this.state.product.id || this.state.product._id,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    if (this.state.status === 'loading') {
      return <LoadingPlaceholder />;
    }
    if (this.state.status === 'error') {
      return <PageError staticContext={this.context} />;
    }
    return (
      <section className="PageCreate">
        {/* Step counter goes here */}
        <Container fluid className="PageCreate__container" ref={this.stepChangeScrollTarget}>
          <Row className="justify-content-center">
            <Col sm={12} md={11}>
              <div className="PageCreate__title">
                <h3>Step { Math.min(this.state.formStep + 1, formSteps.length - 1) } of { formSteps.length - 1 }</h3>
                <span className="PageCreate__help">{helpSteps[this.state.formStep]}</span>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Now here we keep all the recipients, now in the new structure we have a preview for each recipient */}
        {/* So technically we need to wrap each of the below in its own component */}
        <Container className="py-4">
          <Row className="justify-content-center">

            <Col xs={12}>
              { this.state.formStatus === 'loading' && <LoadingPlaceholder /> }

              { this.state.formStatus === 'okay' && (
                <GiftForm
                  formStep={ this.state.formStep }
                  formStepName={ formSteps[this.state.formStep] }
                  product={ this.state.product }
                  orderData={ this.state.orderData }
                  updateOrderData={ this.getDataChangeHandler('orderData') }
                  giftData={ this.state.giftData }
                  updateGiftData={ this.updateGiftData }
                  updateGiftDataByRecipientId={ this.updateGiftDataByRecipientId }
                  orderCompletionData={ this.state.orderCompletionData }
                  updateOrderCompletionData={ this.updateOrderCompletionData }
                  prefillData={ this.state.prefillData }
                  addRecipient={ this.addRecipient }
                  removeRecipient={ this.removeRecipient }
                  markRecipientDataDirty={ this.markRecipientDataDirty }
                  goToNextStep={ this.getFormStepUpdater(1) }
                  goToPreviousStep={ this.getFormStepUpdater(-1) }
                />
              )}
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

}

export default withRouter(PageCreate);
