// Avoids most symbols while still allowing common punctuation and fun names, e.g.:
// John "Who's that?!" Smith
// Mr. Billy 3-Steaks
// ES6 unicode regex: allows also foreign names: Cirinnà, Lindström, Müller, 习近平
export const nameRegex = /^[\p{L} 0-9 ,.'"!?-]+$/iu;
export const namePattern = nameRegex.toString().replace(/^\/|\/iu$/g, '');

// For use alongside <input type="email"> to avoid local (but technically valid) addresses like
// user@localhost. (Just Ensures there is at least a second-level domain part.)
export const emailRegex = /.*@[^.]+(\.[^.]+)+$/;
export const emailPattern = emailRegex.toString().replace(/^\/|\/$/g, '');

export default {
  nameRegex,
  namePattern,
  emailRegex,
  emailPattern,
};
