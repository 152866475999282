import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Collapse, Form } from 'react-bootstrap';
import { postcodeValidator } from 'postcode-validator';

// Wrapper for sets of API calls to enable server-side rendering.
// Just enough to build basic page data and metadata from a provided path.
import api from '../utilities/api';

const data8Script = 'https://webservices.data-8.co.uk/javascript/predictiveaddress.js';
const data8Style = 'https://webservices.data-8.co.uk/content/predictiveaddress.css';

export default function GiftAidPCA(props) {
  const [displayFields, setDisplayFields] = useState(true);
  const [postcodeValidity, setPostcodeValidity] = useState(props.orderData.giftAidAddressPostcode ? postcodeValidator(props.orderData.giftAidAddressPostcode, 'UK') : null);

  // const resetForm = () => {
  //   setPostcodeValidity(props.orderData.giftAidAddressPostcode ? postcodeValidator(props.orderData.giftAidAddressPostcode, 'UK') : null);
  //   props.updateOrderData({ 'giftAidAddressLine1': '' });
  //   props.updateOrderData({ 'giftAidAddressLine2': '' });
  //   props.updateOrderData({ 'giftAidAddressCity': '' });
  //   // props.updateOrderData({ 'giftAidAddressPostcode': '' }); // We don't currently clear the entered postcode when resetting
  //   props.updateOrderData({ 'giftAidAddressSource': false });
  // };
  
  const validatePostcode = (e) => {
    const postcode = e.target.value;
    const valid = postcodeValidator(postcode, 'UK');
    setPostcodeValidity(valid);

    e.currentTarget = e.target;
    props.onChange(e);
  };

  const resetFields = () => {
    props.updateOrderData({ 'giftAidAddressLine1': '' });
    props.updateOrderData({ 'giftAidAddressLine2': '' });
    props.updateOrderData({ 'giftAidAddressCity': '' });
    props.updateOrderData({ 'giftAidAddressPostcode': '' });
    props.updateOrderData({ 'giftAidAddressSource': 'manual_entry' });
    setDisplayFields(false);
  };

  const data8Loaded = async () => {
    const fields = [
      { element: 'giftAidAddressLine1', field: 'line1' },
      { element: 'giftAidAddressLine2', field: 'line2' },
      { element: 'giftAidAddressCity', field: 'town' },
      { element: 'giftAidAddressPostcode', field: 'postcode' }
    ];
    const element = 'giftAidAddressPostcode';
    const search = document.getElementById(element);
    const config = await api.getSiteConfig();
    new window.data8.predictiveaddressui(search, {
      ajaxKey: config.data8ClientApiKey,
      fields: fields,
      allowedCountries: [ 'GB' ],
      // selectAddress(address) {
      selectAddress() {
        setDisplayFields(true);
        props.updateOrderData({ 'giftAidAddressSource': 'from_d8' });
      },
    });
    resetFields();
  };

  useEffect(() => {
    if (!document.getElementById('data8Script')) {
      const script = document.createElement('script');
      script.src = data8Script;
      script.async = true;
      script.id = 'data8Script';
      script.onload = () => data8Loaded();
      document.body.appendChild(script);
    } else {
      data8Loaded();
    }

    if (!document.getElementById('data8Style')) {
      const style = document.createElement('link');
      style.href = data8Style;
      style.rel = 'stylesheet';
      style.id = 'data8Style';
      document.body.appendChild(style);
    }
  }, []);

  return (
    <div className="gift-aid-data-capture">
      <p className="h4">Your address</p>
      <p>We need your residential address to process Gift Aid on your donation.</p>

      <div>
        <Collapse in={displayFields}>
          <div>
            <Form.Group controlId="giftAidAddressLine1">
              <Form.Label>Address line 1*</Form.Label>
              <Form.Control
                placeholder="Address Line 1"
                required={true}
                onChange={(e) => {
                  props.onChange(e);
                }}
                type="text"
                value={props.orderData.giftAidAddressLine1}
              />
            </Form.Group>
            <Form.Group controlId="giftAidAddressLine2">
              <Form.Label>Address line 2</Form.Label>
              <Form.Control
                placeholder="Address Line 2"
                onChange={(e) => {
                  props.onChange(e);
                }}
                type="text"
                value={props.orderData.giftAidAddressLine2}
              />
            </Form.Group>
            <Form.Group controlId="giftAidAddressCity">
              <Form.Label>Town/City*</Form.Label>
              <Form.Control
                placeholder="Town/City"
                required={true}
                onChange={(e) => {
                  props.onChange(e);
                }}
                type="text"
                value={props.orderData.giftAidAddressCity}
              />
            </Form.Group>
          </div>
        </Collapse>
        <Form.Group controlId="giftAidAddressPostcode">
          <Form.Label>Postcode*</Form.Label>
          <Form.Control
            placeholder="Postcode"
            required={true}
            onChange={validatePostcode}
            type="text"
            value={props.orderData.giftAidAddressPostcode}
            isValid={postcodeValidity === true && props.orderData.giftAidAddressSource !== 'from_pca'}
            isInvalid={postcodeValidity === false}
          />
        </Form.Group>
        {displayFields &&
          <p className="gift-aid-text" onClick={() => resetFields()}>Reset selection</p>
        }
        {!displayFields &&
          <p className="gift-aid-text" onClick={() => setDisplayFields(true)}>Enter address manually</p>
        }
      </div>
    </div>
  );
}

GiftAidPCA.propTypes = {
  orderData: propTypes.object,
  onChange: propTypes.func,
  updateOrderData: propTypes.func,
};
