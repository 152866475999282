let environment;

switch (process.env.REACT_APP_BRANCH) {

  case 'master':
    environment = 'production';
    break;

  case 'staging':
    environment = 'staging';
    break;

  case 'develop':
    environment = 'development';
    break;

  case 'local-dev':
  default:
    environment = 'local-development';
    break;

}

const settings = require('./settings.' + environment);

// Init as empty object if not present
settings.default.endpoints = settings.default.endpoints || {};
settings.default.payment = settings.default.payment || {};

// Inject environment var overrides if available
Object.assign(settings.default, {
  endpoints: {
    base: process.env.REACT_APP_BASE_URL || settings.default.endpoints.base,
    content: process.env.REACT_APP_CONTENT_URL || settings.default.endpoints.content,
    api: process.env.REACT_APP_API_URL || settings.default.endpoints.api,
  },
  payment: {
    environment: process.env.DGP_API_BRAINTREE_ENVIRONMENT || settings.default.payment.environment,
  },
});

export default settings.default;
