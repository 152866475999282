import React from 'react';
import propTypes from 'prop-types';

class ErrorPanel extends React.Component {

  static get propTypes() {
    return {
      variant: propTypes.oneOf([
        'square',
        'video',
      ]),
      children: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node,
      ]),
    };
  }

  render() {
    return (
      <div className={`ErrorPanel${ this.props.variant ? ' ' + this.props.variant : ''}`}>
        <div className="content">
          {this.props.children}
        </div>
      </div>
    );
  }

}

export default ErrorPanel;
