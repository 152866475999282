import React from 'react';
import propTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import siteSettings from '../config/settings';

import Message from './Product/Message';

class AnimationCard extends React.Component {

  static get propTypes() {
    return {
      gift: propTypes.object,
      product: propTypes.object,
      config: propTypes.object,
    };
  }

  constructor(props) {
    super(props);

    this.config = Object.assign({},
      {
        autoTrigger: true,
        interactive: true,
        initialStateOverride: {},
        showControls: false,
      },
      props.config,
    );

    this.state = Object.assign({},
      {
        animationStarted: false,
        isEnvelopeActive: false,
        isCardActive: false,
      },
      this.config.initialStateOverride,
    );

    this._isMounted = false;

    this.toggleEnvelope = this.toggleEnvelope.bind(this);
    this.toggleCard = this.toggleCard.bind(this);
    this.activateEnvelope = this.activateEnvelope.bind(this);
    this.activateCard = this.activateCard.bind(this);
  }

  toggleEnvelope() {
    this._isMounted && this.setState({ animationStarted: true });
    this._isMounted && this.setState({ isEnvelopeActive: !this.state.isEnvelopeActive });
  }

  toggleCard() {
    this._isMounted && this.setState({ isCardActive: !this.state.isCardActive });
  }

  activateEnvelope() {
    this._isMounted && this.setState({ isEnvelopeActive: true });
  }

  activateCard() {
    this._isMounted && this.setState({ isCardActive: true });
  }

  componentDidMount() {
    this._isMounted = true;
    // Auto-trigger the animation steps in sequence
    if (this.config.autoTrigger) {
      setTimeout(this.activateEnvelope, 1200);
      setTimeout(this.activateCard, 3000);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const gradientStop1 = '#1a8e5f'; // $darken($extragreen, 20))
    const gradientStop2 = '#34da96'; // $extragreen
    return (
      <>
        <section className={`AnimationCard${this.config.interactive ? '' : ' no-interaction'}`}>
          {/* {/* Dropped more-or-less straight in from codepen https://codepen.io/Jimaginary/pen/XWbEZMg */}
          {/* TODO: Still needs adjusting! */}
          <div className={`animation-wrapper${this.state.animationStarted ? ' started' : ''}`}>
            <div className='envelope-wrapper'>
              <div className={`envelope${this.state.isEnvelopeActive ? ' isActive' : ''}`}>
                <div className='envelope-front' onClick={ this.config.interactive ? this.toggleEnvelope : null }>
                  <p>
                    {this.props.gift.archived === true ?
                      '' :
                      this.props.gift.recipient.name
                    }
                  </p>
                </div>
                <div className='envelope-inside'></div>
                <div className='card-wrapper'>
                  <div className={`card-main${this.state.isCardActive ? ' isActive' : ''}`} onClick={ this.config.interactive ? this.toggleCard : null }>
                    <div className='card-message'>
                      <Message gift={this.props.gift}/>
                    </div>
                    <div className='card-design'>
                      <img src={siteSettings.endpoints.content + this.props.product.assets.image} alt={this.props.product.description} />
                    </div>
                  </div>
                </div>
                <div className='envelope-back'>
                  <svg viewBox='0 0 100 100'>
                    <defs>
                      <linearGradient id='envelope-back' gradientTransform='rotate(70)'>
                        <stop offset='5%' stopColor={gradientStop1} />
                        <stop offset='95%' stopColor={gradientStop2} />
                      </linearGradient>
                    </defs>
                    <path d='M -5 0 L 50 50 L -5 105 Z' fill='url(#envelope-back)' stroke='none' />
                    <path d='M 105 0 L 50 50 L 105 105 Z' fill='url(#envelope-back)' stroke='none' />
                    <path d='M -5 105 L 40 40 S 50 38.5, 60 40 L 105 105 Z' fill='url(#envelope-back)' stroke='rgba(0,0,0,.15)' strokeWidth='.25px' />
                  </svg>
                </div>
                <div className='envelope-flap' onClick={ this.config.interactive ? this.toggleEnvelope : null }>
                  <svg viewBox='0 0 100 100'>
                    <defs>
                      <linearGradient id='envelope-flap' gradientTransform='rotate(120)'>
                        <stop offset='5%' stopColor={gradientStop1} />
                        <stop offset='95%' stopColor={gradientStop2} />
                      </linearGradient>
                    </defs>
                    <path d='M -5 0 L 45 60 S 50 65, 55 60 L 105 0 Z' fill='url(#envelope-flap)' stroke='rgba(0,0,0,.15)' strokeWidth='.25px' />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </section>
        { this.config.showControls &&
        <>
          <Row className="justify-content-center py-2">
            <Col xs={12} sm={8} lg={6}>
              <div  style={{
                transition:  `opacity 300ms ${this.state.isEnvelopeActive ? '500' : '0'}ms ease-in-out`,
                opacity: this.state.isEnvelopeActive ? 1 : 0,
                pointerEvents: this.state.isEnvelopeActive ? null : 'none',
              }}>
                <button className="btn btn-block btn-primary" onClick={this.state.isEnvelopeActive ? this.toggleCard : null}>Flip Card</button>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center py-2">
            <Col xs={12} sm={8} lg={6}>
              <button className="btn btn-block btn-outline-primary" onClick={this.toggleEnvelope}>Flip Envelope</button>
            </Col>
          </Row>
        </>
        }
      </>
    );
  }

}

export default AnimationCard;
