import React from 'react';
import propTypes from 'prop-types';
import BraintreeWeb from 'braintree-web';

import { getPaymentProviderToken, submitOrder } from '../../utilities/api';
import { generateOrderSubmissionData } from '../../utilities/transformations';

import siteSettings from '../../config/settings';
import ExpressCheckoutContainer from './ExpressCheckoutContainer';

// Loading paypal-checkout IMMEDIATELY runs code that expects to be run in a
// browser (requires the 'window' global) so we dodge that for the server build.
let paypal = null;
if (process.browser) {
  paypal = require('paypal-checkout');
}

class PaymentPaypal extends React.Component {

  constructor(props) {
    super(props);
    [
      'onError',
      'updatePaypalReadyStatus',
    ].forEach(prop => (this[prop] = this[prop].bind(this)));

    this.state = {
      buttonEnabled: true,
      paypalReady: false,
      authorization: null,
      payloadNonce: null,
    };


    this.paypalStarted = false;

    // TODO: Move to componentDidMount?
    getPaymentProviderToken('braintree')
      .then( (value) => {
        if (value.success !== true) {
          throw new Error('Braintree payment failed to get client token', value);
        }
        this.setState({
          status: 'okay',
          authorization: value.clientToken,
        });
      })
      .catch( (error) => {
        this.setState({
          status: 'error',
          authorization: null,
        });
        console.error(error);
      } );
  }

  static get propTypes() {
    return {
      orderData: propTypes.object,
      giftData: propTypes.object,
      updateOrderCompletionData: propTypes.func,
      goToNextStep: propTypes.func,
      totalValue: propTypes.number,
    };
  }

  updatePaypalReadyStatus (status) {
    this.setState({ paypalReady: status });
  }

  onError(error) {
    console.error(error);
    this.setState({ error });
  }

  validateSenderDetailsForm() {
    // Validate the sender details form
    let form = document.getElementById('sender-details-form');
    if (form.length && form.checkValidity() === false) {
      if (form.reportValidity) {
        form.reportValidity();
        return false;
      }
    }
    // If we get past this point the form appears valid (client-side), so we continue
    return true;
  }

  render() {
    // Skip for SSR
    if (typeof window === 'undefined') {
      return null;
    }

    // Create local references to avoid 'this' issues within
    // anonymous functions in the promise chain below
    const transactionValue = this.props.totalValue;
    const goToNextStep = this.props.goToNextStep;
    const getGiftData = () => this.props.giftData;
    const getOrderData = () => this.props.orderData;
    const updatePaypalReadyStatus = this.updatePaypalReadyStatus;
    const validateSenderDetailsForm = this.validateSenderDetailsForm;
    const updateOrderCompletionData = this.props.updateOrderCompletionData;
    const setState = this.setState.bind(this);

    if (this.state.authorization !== null && !this.paypalStarted) {
      this.paypalStarted = true;

      BraintreeWeb.client.create({
        authorization: this.state.authorization,
      }).then(function (clientInstance) {
        return BraintreeWeb.paypalCheckout.create({
          client: clientInstance,
        }, function (error, paypalCheckoutInstance) {
          if (error) {
            console.error(error);
            return;
          }

          paypal.Button.render({
            env: siteSettings.payment.environment.toLowerCase(), // sandbox or 'production'
            locale: 'en_GB',
            style: {
              size: 'large',
              color: 'silver',
              shape: 'rect',
            },

            payment: function () {
              setState({ buttonEnabled: false });
              // Validate the sender detail form
              // NOTE: Returning 'false' here causes console errors, and doesn't actually
              // prevent the PayPal pop-up from firing - but it immediately fails and returns
              // to the browser with HTML5 validation errors shown.
              if (!validateSenderDetailsForm()) {
                setState({ buttonEnabled: true });
                return false;
              }
              return paypalCheckoutInstance.createPayment({
                flow: 'checkout',
                amount: transactionValue,
                currency: 'GBP',
              });
            },

            onAuthorize: function (data) {
              setState({ buttonEnabled: false });
              return paypalCheckoutInstance.tokenizePayment(data)
                .then(function (payload) {
                  // Submit payment to server
                  const order = generateOrderSubmissionData(getOrderData(), getGiftData());

                  const customer = order.user;

                  const transaction = {
                    processor: 'paypal',
                    paymentMethodNonce: payload.nonce,
                    amount: transactionValue,
                    customer,
                  };

                  submitOrder(order, transaction)
                    .then( (response) => {
                      if (response.success) {
                        updateOrderCompletionData(response);
                        goToNextStep();
                      } else {
                        // TODO: Handle PayPal failure responses
                        // (Should be cleanly handled within the pop-up though...)
                        // setState({
                        //   checkoutMessage: 'Error! Transaction failed: ' + response.message,
                        //   buttonEnabled: true,
                        // });
                      }
                    });
                });
            },

            // Fired when PayPal has fully rendered the button
            onEnter: function () {
              updatePaypalReadyStatus(true);
            },

            onCancel: function() {
              setState({ buttonEnabled: true });
            },

            onError: function (error) {
              console.error(error);
              updatePaypalReadyStatus(true);
            },
          }, '#paypal-button'); // The PayPal button will be rendered into a DOM element with this id
        });
      });
    }

    return (
      <ExpressCheckoutContainer
        className="Payment PaymentExpressCheckout PaymentPayPal"
        status={this.state.status}
        paymentReady={this.state.paypalReady}
        buttonEnabled={this.state.buttonEnabled}
        helpMessage="To pay with PayPal, please click the button below."
        checkoutMessage={this.state.checkoutMessage}
      >
        <div id="paypal-button"></div>
      </ExpressCheckoutContainer>
    );
  }

}

export default PaymentPaypal;
