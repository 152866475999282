import React from 'react';
import propTypes from 'prop-types';

import LoadingPlaceholder from '../LoadingPlaceholder';
import Error from '../Error';
import Product from '../Product';

class ProductPreview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      previewMode: props.previewMode || 'product', // Other options are 'message' and 'gift'
    };
    this._isMounted = false;
  }

  static get propTypes() {
    return {
      status: propTypes.string,
      product: propTypes.object,
      contentEndpoint: propTypes.string,
    };
  }

  getPreviewModeSetter(previewMode) {
    return () => this.setState({ previewMode });
  }

  componentDidMount() {
    this._isMounted = true;
    // If we received a product object, use it as-is
    if (this.props.product) {
      this._isMounted && this.setState({
        status: 'okay',
        product: this.props.product,
      });
      return;
    }

    // If we get to here something's gone horribly wrong
    this._isMounted && this.setState({
      status: 'error',
      product: null,
    });
    return;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    if (this.props.status === 'loading') {
      return <LoadingPlaceholder />;
    }

    if (this.props.status === 'error') {
      return <Error />;
    }

    const previewModeMapping = {
      product: 'full',
      message: 'message',
      gift: 'gift',
    };

    // We use a `key` here to trigger a restart of the animation when the active
    // giftData changes. (Mostly to enhance the "preview" step.)
    const preview = <Product key={this.props.giftData.id} {...this.props} mode={previewModeMapping[this.state.previewMode]}/>;

    return (
      <div className="ProductPreview">

        <div className="preview-wrapper-outer">
          <div className="preview-wrapper-inner">
            { preview }
          </div>
        </div>

        <div className="mt-2 mb-4 d-inline-flex w-100 justify-content-center preview">

          <div className={`preview-mode-button${ this.state.previewMode === 'product' ? ' active' : ''}`} onClick={this.getPreviewModeSetter('product')}>
            <div className="preview-mode-button-icon">
              <Product {...this.props} mode="thumbnail" />
            </div>
          </div>

          <div className={`preview-mode-button${ this.state.previewMode === 'message' ? ' active' : ''}`} onClick={this.getPreviewModeSetter('message')}>
            <div className="preview-mode-button-icon">
              <div className="boost-to-scale">
                <Product {...this.props} mode="message" isButton={true} />
              </div>
            </div>
          </div>

          <div className={`preview-mode-button${ this.state.previewMode === 'gift' ? ' active' : ''}`} onClick={this.getPreviewModeSetter('gift')}>
            <div className="preview-mode-button-icon">
              <img
                className="img-fluid"
                src={this.props.contentEndpoint + 'theme/gift-preview-unwrapping-placeholder.jpg'} alt="Placeholder"
              />
              <img
                className="unwrapping-inset"
                src={this.props.contentEndpoint + this.props.product.assets.image + '?style=thumbnail'} alt="Placeholder"
              />
              <img
                className="unwrapping-play-button"
                src={this.props.contentEndpoint + 'theme/gift-preview-unwrapping-play-button.png'} alt="Placeholder"
              />
            </div>
          </div>

        </div>

      </div>
    );
  }

}

export default ProductPreview;
