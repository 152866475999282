import PageHome from '../components/PageHome';
import PageError from '../components/PageError';
import PageCategory from '../components/PageCategory';
import PageCreate from '../components/PageCreate';
import PageGift from '../components/PageGift';
import PageStatic from '../components/PageStatic';
import PageThanks from '../components/PageThanks';
import PageUser from '../components/PageUser';

const Routes = [
  {
    path: '/',
    exact: true,
    component: PageHome,
  },
  {
    path: '/create/:productSlug',
    exact: true,
    component: PageCreate,
    ssrDataRequired: true,
  },
  {
    path: '/thanks/:orderHash',
    exact: true,
    component: PageThanks,
    ssrDataRequired: true,
  },
  {
    path: '/gift/:giftHash',
    exact: true,
    component: PageGift,
  },
  {
    path: '/user/:userHash',
    exact: true,
    component: PageUser,
  },
  {
    path: '/category/:categorySlug',
    exact: true,
    component: PageCategory,
  },
  {
    path: '/:pageSlug',
    exact: true,
    component: PageStatic,
    ssrDataRequired: true,
  },
  {
    component: PageError,
  },
];

export default Routes;
