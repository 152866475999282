import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import LoadingPlaceholder from '../LoadingPlaceholder';

import tracking from '../../utilities/tracking';
import { submitOrder, getProductById } from '../../utilities/api';
import { generateOrderSubmissionData } from '../../utilities/transformations';

const sendOrderCompleteTrackingEvents = async (orderCompletionData) => {
  // This function (and the utilities/tracking functions it relies on) will
  // need reworking to handle orders containing multiple products

  // Inject addition product info into each gift object
  const gifts = await Promise.all(orderCompletionData.order.gifts.map( async (gift) => {
    const product = await getProductById(gift.productId);
    return {
      ...gift,
      productName: product.name,
      productSlug: product.slug,
    };
  }));

  // GA goal and eCommerce tracking
  // Basic product send
  // (Currently we just use the first -- and only -- gift from the "gifts" array)
  tracking.productSend({
    value: (orderCompletionData.transaction && orderCompletionData.transaction.amount) || 0,
    productName: gifts[0].productName,
    productSlug: gifts[0].productSlug,
  });

  // GDPR consent
  ['email', 'post', 'phone'].forEach( (channel) => {
    if (orderCompletionData.order.consent[channel] !== undefined) {
      tracking.consent({
        channel,
        choice: orderCompletionData.order.consent[channel],
      });
    }
  });

  // Gift Aid
  if (orderCompletionData.order.giftAid.giftAidOptIn) {
    tracking.giftaid();
  }

  // eCommerce and donation tracking if we have a non-zero transaction amount.
  // (Currently we can only send multiple of the same product, so we just use
  // the first gift object to collect product details and "real" value, along
  // with the gift array's length as the count)
  if (orderCompletionData.transaction && orderCompletionData.transaction.amount) {
    tracking.purchase({
      transactionId: `dgp_${orderCompletionData.orderHash}/${orderCompletionData.transaction.id}`,
      value: orderCompletionData.transaction.amount,
      frequency: 'cash',
      count: gifts.length,
      productName: gifts[0].productName,
      productSlug: gifts[0].productSlug,
      productId: gifts[0].productId,
    });
  }
};

export default function GiftFormProcess(props) {
  const [submissionStatus, setSubmissionStatus] = useState( undefined );
  const [localOrderCompletionData, setLocalOrderCompletionData] = useState( undefined );

  // Function called to handle submissions without a transaction
  // (Orders WITH a transaction are submitted during the payment processing)
  const placeOrder = () => {
    setSubmissionStatus(false);

    const orderData = generateOrderSubmissionData(props.orderData, props.giftData);
    // Submit using the pass-through "none" payment processor
    submitOrder(orderData, { processor: 'none' })
      .then( (result) => {
        setLocalOrderCompletionData(result);
        setSubmissionStatus(true);
      });
  };

  // If we've already successfully submitted the order (with a payment) we
  // can send tracking events and move on straight away.
  if (props.orderCompletionData && props.orderCompletionData.success) {
    sendOrderCompleteTrackingEvents(props.orderCompletionData);
    sessionStorage.removeItem('giftData');
    sessionStorage.removeItem('orderData');
    sessionStorage.setItem('formStep', 0);
    return ( <Redirect push to={`/thanks/${props.orderCompletionData.orderHash}`} /> );
  }

  // Save data to database. check for return from savedata
  if (typeof submissionStatus === 'undefined') {
    placeOrder();
  }

  if (submissionStatus === false) {
    return <LoadingPlaceholder />;
  }

  if (submissionStatus === true && typeof localOrderCompletionData.orderHash !== 'undefined') {
    sendOrderCompleteTrackingEvents(localOrderCompletionData);
    sessionStorage.removeItem('giftData');
    sessionStorage.removeItem('orderData');
    // localStorage.removeItem('foeData'); // TODO: Decide on expiration of foeData
    sessionStorage.setItem('formStep', 0);
    return ( <Redirect push to={`/thanks/${localOrderCompletionData.orderHash}`} /> );
  }

  return (
    <div className="error">
      An error occurred. Please contact <a href="https://friendsoftheearth.uk/contact?enquiry_type=general&enquiry_detail_general=gifts" rel="noopener noreferrer" target="_blank">our supporter care team</a>.
    </div>
  );
}

GiftFormProcess.propTypes = {
  product: propTypes.object,
  giftData: propTypes.object,
  orderData: propTypes.object,
  transactionData: propTypes.object,
  orderCompletionData: propTypes.object,
};
