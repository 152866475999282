import React from 'react';
import propTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import PageError from './PageError';
import LoadingPlaceholder from './LoadingPlaceholder';
import Product from './Product';

import { getPageBySlug, getGiftByHash, getProductById } from '../utilities/api';
import setWindowTitle from '../utilities/set-window-title';

class PageStatic extends React.Component {

  constructor(props) {
    super(props);

    // Set default state
    this.state = {
      status: 'loading',
      page: null,
    };

    // Pick up and clear server-side provided context
    this.state.context = props.staticContext;
    if (typeof window !== 'undefined' && window.__INITIAL_CONTEXT__) {
      this.state.context = window.__INITIAL_CONTEXT__;
      window.__INITIAL_CONTEXT__ = false;
    }

    // If we already have context populate the state
    if (this.state.context && this.state.context.data && this.state.context.data.page) {
      this.state.status = 'okay';
      this.state.page = this.state.context.data.page;
    }

    this._isMounted = false;
  }

  static get propTypes() {
    return {
      match: propTypes.object,
      location: propTypes.object,
      staticContext: propTypes.object,
    };
  }

  loadPageContentBySlug(slug) {
    this._isMounted && this.setState({
      page: {},
      status: 'loading',
    });

    getPageBySlug(slug)
      .then((value) => {
        if (!value) {
          throw new Error(`Page with slug "${slug}" not found`);
        }
        // Update the window title
        setWindowTitle(value.name);
        if (value.giftId) {
          // Get gift details
          getGiftByHash(value.giftId)
            .then((gift) => {
              if (typeof gift === 'undefined') {
                value.gift = null;
                this._isMounted && this.setState({
                  page: value,
                  status: 'okay',
                });
              } else {
                getProductById(gift.productId)
                  .then((product) => {
                    gift.product = product;
                    gift.status = false; // Disables the controls (?!)
                    value.gift = gift;
                    this._isMounted && this.setState({
                      page: value,
                      status: 'okay',
                    });
                  });
              }
            });
        } else {
          this._isMounted && this.setState({
            page: value,
            status: 'okay',
          });
        }
      })
      .catch((error) => {
        this._isMounted && this.setState({
          page: {},
          status: 'error',
        });
        console.error(error);
      });
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.state.page) {
      this.loadPageContentBySlug(this.props.match.params.pageSlug);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.loadPageContentBySlug(nextProps.match.params.pageSlug);
    }
  }

  render() {
    if (this.state.status === 'loading') {
      return <LoadingPlaceholder />;
    }
    if (this.state.status === 'error') {
      return <PageError staticContext={this.state.context} />;
    }
    return <>
      <Container className="py-4">
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8}>

            <h1>{this.state.page.name}</h1>

            <hr />

            {/* Intro copy (optional) */}
            {!!this.state.page.intro && <ReactMarkdown source={this.state.page.intro} escapeHtml={false} />}

            {/* Gift, if available */}
            {!!this.state.page.gift && <>
              <Row className="py-2 justify-content-center">
                <Col xs={12} sm={10} md={8} lg={6}>
                  <Product mode="gift" gift={this.state.page.gift} product={this.state.page.gift.product} />
                </Col>
              </Row>
            </>}

            {/* Warning for invalid gift ID */}
            {this.state.page.gift === null && <>
              <div className="alert alert-warning text-center">
                A gift ID was provided for this page but no matching gift record was found.
              </div>
            </>}

            {/* Main page copy */}
            <ReactMarkdown source={this.state.page.content} escapeHtml={false} />

            {/* CTA, if provided */}
            {!!this.state.page.cta && !!this.state.page.ctaLabel &&
              <Link className="btn btn-primary mb-4" to={this.state.page.cta}>{this.state.page.ctaLabel}</Link>
            }

          </Col>
        </Row>
      </Container>
    </>;
  }

}

export default withRouter(PageStatic);
