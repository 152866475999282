import React from 'react';
import propTypes from 'prop-types';
import BraintreeWeb from 'braintree-web';

import { getPaymentProviderToken, submitOrder } from '../../utilities/api';
import { generateOrderSubmissionData } from '../../utilities/transformations';
import siteSettings from '../../config/settings';

import ExpressCheckoutContainer from './ExpressCheckoutContainer';

class PaymentGooglePay extends React.Component {

  constructor(props) {
    super(props);
    [
      'onError',
      'updateGooglePayReadyStatus',
    ].forEach(prop => (this[prop] = this[prop].bind(this)));

    this.state = {
      buttonEnabled: true,
      googlepayReady: false,
      authorization: null,
      googleMerchantId: null,
      payloadNonce: null,
    };
    this.googleButton = React.createRef();
    this.googlepayStarted = false;

    // TODO: Move to componentDidMount?
    getPaymentProviderToken('braintree')
      .then( (value) => {
        if (value.success !== true) {
          throw new Error('Braintree payment failed to get client token', value);
        }
        this.setState({
          status: 'okay',
          authorization: value.clientToken,
          googleMerchantId: value.googleMerchantId,
        });
      })
      .catch( (error) => {
        this.setState({
          status: 'error',
          authorization: null,
        });
        console.error(error);
      } );
  }

  static get propTypes() {
    return {
      orderData: propTypes.object,
      giftData: propTypes.object,
      updateOrderCompletionData: propTypes.func,
      goToNextStep: propTypes.func,
      totalValue: propTypes.number,
    };
  }

  updateGooglePayReadyStatus (status) {
    this.setState({ googlepayReady: status });
  }

  onError(error) {
    console.error(error);
    this.setState({ error });
  }

  validateSenderDetailsForm() {
    // Validate the sender details form
    let form = document.getElementById('sender-details-form');
    if (form.length && form.checkValidity() === false) {
      if (form.reportValidity) {
        form.reportValidity();
        return false;
      }
    }
    // If we get past this point the form appears valid (client-side), so we continue
    return true;
  }

  render() {
    // Skip for SSR
    if (typeof window === 'undefined') {
      return null;
    }

    // Fetch preloaded SDK
    const { google } = window;

    // Create local references to avoid 'this' issues within
    // anonymous functions in the promise chain below
    const transactionValue = this.props.totalValue.toString();
    const goToNextStep = this.props.goToNextStep;
    const getGiftData = () => this.props.giftData;
    const getOrderData = () => this.props.orderData;
    const updateGooglePayReadyStatus = this.updateGooglePayReadyStatus;
    const validateSenderDetailsForm = this.validateSenderDetailsForm;
    const updateOrderCompletionData = this.props.updateOrderCompletionData;
    const setState = this.setState.bind(this);

    if (this.state.authorization !== null && !this.googlepayStarted) {
      this.googlepayStarted = true;

      BraintreeWeb.client.create({
        authorization: this.state.authorization,
      })
        .then((clientInstance) => {
          return BraintreeWeb.googlePayment.create({
            client: clientInstance,
            googlePayVersion: 2,
            googleMerchantId: this.state.googleMerchantId,
          });
        })
        .then((googlePayInstance) => {
          updateGooglePayReadyStatus(true);

          const paymentsClient = new google.payments.api.PaymentsClient({
            environment: siteSettings.payment.environment.toLowerCase() === 'production' ? 'PRODUCTION' : 'TEST',
          });

          paymentsClient.isReadyToPay({
            // see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest for all options
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: googlePayInstance.createPaymentDataRequest().allowedPaymentMethods,
            existingPaymentMethodRequired: true,
          })
            .then((response) => {
              if (response.result) {
                const gpayButton = paymentsClient.createButton({
                  buttonColor: 'default',
                  buttonType: 'donate',
                  buttonSizeMode: 'fill',
                  onClick: () => {
                    setState({ buttonEnabled: false });

                    if (!validateSenderDetailsForm()) {
                      setState({ buttonEnabled: true });
                      return false;
                    }

                    const paymentDataRequest = googlePayInstance.createPaymentDataRequest({
                      transactionInfo: {
                        currencyCode: 'GBP',
                        totalPriceStatus: 'FINAL',
                        totalPrice: transactionValue,
                      },
                    });

                    // Ask for the user to have at least 1 card registered
                    // and with address details
                    // https://developers.google.com/pay/api/web/reference/object
                    const [firstRegisteredCard] = paymentDataRequest.allowedPaymentMethods;
                    firstRegisteredCard.parameters.billingAddressRequired = true;
                    firstRegisteredCard.parameters.billingAddressParameters = {
                      format: 'FULL',
                      phoneNumberRequired: true,
                    };

                    paymentsClient.loadPaymentData(paymentDataRequest)
                      .then((data) => {
                        googlePayInstance.parseResponse(data, (err, payload) => {
                          if (err) {
                            setState({
                              checkoutMessage: 'Error! Transaction failed: ' + err.message,
                              buttonEnabled: true,
                            });
                            console.warn(err);
                            return;
                          }
                          const order = generateOrderSubmissionData(getOrderData(), getGiftData());

                          // Address format from google:
                          // data.info.billingAddress: {
                          //   "phoneNumber": "+44 0123 123456",
                          //   "address3": "",
                          //   "sortingCode": "",
                          //   "address2": "Test Street",
                          //   "countryCode": "GB",
                          //   "address1": "Flat 123, Sandbox Building",
                          //   "postalCode": "AA11 1AA",
                          //   "name": "John Doe",
                          //   "locality": "London",
                          //   "administrativeArea": ""
                          // }

                          // Map Braintree keys to Googlepay keys
                          const { billingAddress } = data.paymentMethodData.info;
                          const [firstName, ...restName] = billingAddress.name.split(' ');

                          const billing = {
                            firstName: firstName,
                            lastName: restName.join(' '),
                            countryCodeAlpha2: billingAddress.countryCode,
                            locality: billingAddress.locality,
                            postalCode: billingAddress.postalCode,
                            region: billingAddress.administrativeArea,
                            streetAddress: billingAddress.address1,
                            extendedAddress: billingAddress.address2 || '',
                          };

                          const customer = {
                            phone: billingAddress.phoneNumber,
                            ...order.user,
                          };

                          const transaction = {
                            processor: 'googlepay',
                            paymentMethodNonce: payload.nonce,
                            amount: transactionValue,
                            billing,
                            customer,
                          };

                          submitOrder(order, transaction)
                            .then( (response) => {
                              if (response.success) {
                                updateOrderCompletionData(response);
                                goToNextStep();
                              } else {
                                setState({
                                  checkoutMessage: 'Error! Transaction failed: ' + response.message,
                                  buttonEnabled: true,
                                });
                              }
                            });
                        });
                      })
                      .catch((err) => {
                        setState({
                          checkoutMessage: 'We need your authorisation for proceeding with Google Pay',
                          buttonEnabled: true,
                        });
                        console.warn(err);
                      });
                  },
                });

                this.googleButton.current.appendChild(gpayButton);
              }
            })
            .catch((err) => {
              setState({
                checkoutMessage: 'Your device is not enabled to pay with Google Pay',
                buttonEnabled: true,
              });

              console.warn(err);
            });
        })
        .catch((err) => {
          console.warn(err);
        });
    }

    return (
      <ExpressCheckoutContainer
        className="Payment PaymentExpressCheckout PaymentGooglePay"
        status={this.state.status}
        paymentReady={this.state.googlepayReady}
        buttonEnabled={this.state.buttonEnabled}
        helpMessage="To pay with Google Pay, please click the button below."
        checkoutMessage={this.state.checkoutMessage}
      >
        <div ref={this.googleButton} />
      </ExpressCheckoutContainer>
    );
  }

}

export default PaymentGooglePay;
