import React from 'react';
import propTypes from 'prop-types';
import BraintreeWeb from 'braintree-web';

import { getPaymentProviderToken, submitOrder } from '../../utilities/api';
import { generateOrderSubmissionData } from '../../utilities/transformations';

import ExpressCheckoutContainer from './ExpressCheckoutContainer';

class PaymentApplePay extends React.Component {

  constructor(props) {
    super(props);
    [
      'onError',
      'updateApplePayReadyStatus',
    ].forEach(prop => (this[prop] = this[prop].bind(this)));

    this.state = {
      buttonEnabled: true,
      applepayReady: false,
      authorization: null,
      payloadNonce: null,
    };
    this.appleButton = React.createRef();
    this.applepayStarted = false;

    // TODO: Move to componentDidMount?
    getPaymentProviderToken('braintree')
      .then( (value) => {
        if (value.success !== true) {
          throw new Error('Braintree payment failed to get client token', value);
        }
        this.setState({
          status: 'okay',
          authorization: value.clientToken,
        });
      })
      .catch( (error) => {
        this.setState({
          status: 'error',
          authorization: null,
        });
        console.error(error);
      } );
  }

  static get propTypes() {
    return {
      orderData: propTypes.object,
      giftData: propTypes.object,
      updateOrderCompletionData: propTypes.func,
      goToNextStep: propTypes.func,
      totalValue: propTypes.number,
    };
  }

  updateApplePayReadyStatus (status) {
    this.setState({ applepayReady: status });
  }

  onError(error) {
    console.error(error);
    this.setState({ error });
  }

  validateSenderDetailsForm() {
    // Validate the sender details form
    let form = document.getElementById('sender-details-form');
    if (form.length && form.checkValidity() === false) {
      if (form.reportValidity) {
        form.reportValidity();
        return false;
      }
    }
    // If we get past this point the form appears valid (client-side), so we continue
    return true;
  }

  render() {
    // Skip for SSR
    if (typeof window === 'undefined') {
      return null;
    }

    // Fetch preloaded SDK
    const { ApplePaySession } = window;

    // Create local references to avoid 'this' issues within
    // anonymous functions in the promise chain below
    const transactionValue = this.props.totalValue.toString();
    const goToNextStep = this.props.goToNextStep;
    const getGiftData = () => this.props.giftData;
    const getOrderData = () => this.props.orderData;
    const updateApplePayReadyStatus = this.updateApplePayReadyStatus;
    const validateSenderDetailsForm = this.validateSenderDetailsForm;
    const updateOrderCompletionData = this.props.updateOrderCompletionData;
    const setState = this.setState.bind(this);

    if (this.state.authorization !== null && !this.applepayStarted) {
      this.applepayStarted = true;

      BraintreeWeb.client.create({
        authorization: this.state.authorization,
      })
        .then((clientInstance) => {
          return BraintreeWeb.applePay.create({
            client: clientInstance,
          });
        })
        .then((applePayInstance) => {
          updateApplePayReadyStatus(true);

          this.appleButton.current.addEventListener('click', () => {
            setState({ buttonEnabled: false });

            if (!validateSenderDetailsForm()) {
              setState({ buttonEnabled: true });
              return false;
            }

            const paymentRequest = applePayInstance.createPaymentRequest({
              total: {
                label: 'Friends of the Earth Trust',
                amount: transactionValue,
              },
              // Request the customer at least the postcode to
              // be registered in their apple pay account.
              requiredBillingContactFields: ['postalAddress'],
            });
            const session = new ApplePaySession(3, paymentRequest);

            session.onvalidatemerchant = (event) => {
              applePayInstance.performValidation({
                validationURL: event.validationURL,
                displayName: 'Friends of the Earth Trust',
              })
                .then((merchantSession) => {
                  session.completeMerchantValidation(merchantSession);
                })
                .catch((err) => {
                  setState({
                    checkoutMessage: 'Sorry we have temporary problems on processing Apple Pay payments right now. Please call our Supporter Care Team 0800 581 051 Monday - Friday 9am-5pm.',
                    buttonEnabled: true,
                  });

                  console.error(err);
                  session.abort();
                });
            };

            session.onpaymentauthorized = (event) => {
              applePayInstance.tokenize({
                token: event.payment.token,
              })
                .then((payload) => {
                  session.completePayment(ApplePaySession.STATUS_SUCCESS);

                  const order = generateOrderSubmissionData(getOrderData(), getGiftData());

                  const { billingContact } = event.payment;
                  // Event here contains interesting info.
                  // eg:
                  // event.payment.billingContact: {
                  //   addressLines: [
                  //     "Flat 123, Sandbox Building",
                  //     "Test Street"
                  //   ],
                  //   administrativeArea: ""
                  //   country: "United Kingdom"
                  //   countryCode: "GB"
                  //   familyName: "Doe"
                  //   givenName: "John"
                  //   locality: "London"
                  //   phoneticFamilyName: ""
                  //   phoneticGivenName: ""
                  //   postalCode: "AA11 1AA"
                  //   subAdministrativeArea: ""
                  //   subLocality: ""
                  // }
                  // Map Braintree keys to Applepay keys
                  const billing = {
                    firstName: billingContact.givenName,
                    lastName: billingContact.familyName,
                    countryCodeAlpha2: billingContact.countryCode,
                    countryName: billingContact.country,
                    locality: billingContact.locality,
                    postalCode: billingContact.postalCode,
                    region: billingContact.subLocality,
                    streetAddress: billingContact.addressLines[0],
                    extendedAddress: billingContact.addressLines[1] || '',
                  };

                  const customer = order.user;

                  const transaction = {
                    processor: 'applepay',
                    paymentMethodNonce: payload.nonce,
                    amount: transactionValue,
                    billing,
                    customer,
                  };

                  submitOrder(order, transaction)
                    .then( (response) => {
                      if (response.success) {
                        updateOrderCompletionData(response);
                        goToNextStep();
                      } else {
                        setState({
                          checkoutMessage: 'Error! Transaction failed: ' + response.message,
                          buttonEnabled: true,
                        });
                      }
                    });
                })
                .catch((err) => {
                  session.completePayment(ApplePaySession.STATUS_FAILURE);
                  setState({
                    checkoutMessage: 'We need your authorisation for proceeding with Apple Pay',
                    buttonEnabled: true,
                  });
                  console.warn(err);
                });
            };

            session.oncancel = () => {
              setState({ buttonEnabled: true });
            };

            session.begin();
          });
        })
        .catch((err) => {
          console.warn(err);
        });
    }

    return (
      <ExpressCheckoutContainer
        className="Payment PaymentExpressCheckout PaymentApplePay"
        status={this.state.status}
        paymentReady={this.state.applepayReady}
        buttonEnabled={this.state.buttonEnabled}
        helpMessage="To pay with Apple Pay, please click the button below."
        checkoutMessage={this.state.checkoutMessage}
      >
        <apple-pay-button
          ref={this.appleButton}
          type="donate"
          locale="en-GB"
          buttonstyle="white-outline"
        />
      </ExpressCheckoutContainer>
    );
  }

}

export default PaymentApplePay;
