import React from 'react';
import propTypes from 'prop-types';
import { Col } from 'react-bootstrap';

import LoadingPlaceholder from '../LoadingPlaceholder';

function ExpressCheckoutContainer({
  className = '',
  children = null,
  status = 'loading',
  paymentReady = false,
  buttonEnabled = false,
  helpMessage = null,
  checkoutMessage = '',
}) {
  if (!children) {
    console.warn('ExpressCheckoutContainer requires a React children');
    return null;
  }
  // We have to RENDER the element form so that the setup process
  // can find it in the DOM
  // ...But we don't have to SHOW it until it's ready
  const hiddenStyle = {
    height: 0,
    overflow: 'hidden',
  };
  return (<>
    { (status === 'loading' || !paymentReady ) && <LoadingPlaceholder /> }

    <div className={className} style={ paymentReady ? null : hiddenStyle }>
      <Col md={12} className={`py-4 my-4 d-flex flex-column payment-container${buttonEnabled ? '' : ' disabled'}`}>
        {helpMessage ? <p><small>{helpMessage}</small></p> : null}
        {checkoutMessage ? <p className="alert alert-warning">{checkoutMessage}</p> : null}
        {children}
      </Col>
    </div>
  </>);
}

ExpressCheckoutContainer.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
  status: propTypes.oneOfType([
    propTypes.string,
    propTypes.bool,
  ]),
  paymentReady: propTypes.bool,
  buttonEnabled: propTypes.bool,
  helpMessage: propTypes.string,
  checkoutMessage: propTypes.string,
};

export default ExpressCheckoutContainer;
