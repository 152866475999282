import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class AboutUs extends React.Component {

  render() {
    return (
      <section className="AboutUs">
        <Container>
          <Row className="py-5 justify-content-center">
            <Col md={12} className="text-center mb-5">
              <h1>About Friends of the Earth</h1>
              <hr />
              <p className="py-3">Friends of the Earth is a grassroots environmental campaigning community. We stand with anyone who cares about people and our planet. From our campaigners and lawyers to local groups and supporters, we push for change on causes that matter to you.</p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

}

export default AboutUs;
