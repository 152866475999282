import React from 'react';
import propTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import Page from './components/Page';
import BrowserSupportPage from './components/BrowserSupportPage';
import LoadingPlaceholder from './components/LoadingPlaceholder';
import MaintenancePage from './components/MaintenancePage';
import PageCategory from './components/PageCategory';
import PageCreate from './components/PageCreate';
import PageError from './components/PageError';
import PageGift from './components/PageGift';
import PageHome from './components/PageHome';
import PageStatic from './components/PageStatic';
import PageThanks from './components/PageThanks';
import PageUser from './components/PageUser';

import { getSiteConfig } from './utilities/api';
import { storeFoeData } from './utilities/foe-data';
import ScrollToTop from './components/ScrollToTop';

class App extends React.Component {

  constructor(props) {
    super(props);

    // We grab the initial client-side context here, but we don't clear it.
    // (Individual PageXxxxx components grab and clear downstream.)
    const context = props.ssrContext || window.__INITIAL_CONTEXT__;

    // Set the default initial state
    this.state = {
      status: 'loading',
      config: {},
      context: {},
    };

    // If we have context we can set the page up
    if (context) {
      // If we have config from context, load it into state
      if (context.config) {
        this.state.config = context.config;
        this.state.context = context;
      }

      if (context.data) {
        // If we have valid-looking data from context we're good to go
        this.state.status = 'okay';
      } else {
        // Otherwise it's almost certainly an error page
        this.state.status = 'error';
      }
    }
  }

  static get propTypes() {
    return {
      ssrContext: propTypes.object,
    };
  }

  componentDidMount() {
    // This serves as an early high-level test of the API endpoint
    if (this.state.status === 'loading') {
      getSiteConfig()
        .then((configData) => {
          this.setState({ status: 'okay', config: configData });
        })
        .catch(() => {
          this.setState({ status: 'error' });
        });
    }
  }

  render() {
    if (typeof window !== 'undefined') {
      // True for IE 6-11
      const isIE = /*@cc_on!@*/false || !!document.documentMode;
      // False on Edge and ie<11
      // const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

      if (isIE) { // We used to give IE11 a pass, but NOT ANY MORE
        return <BrowserSupportPage />;
      }

      storeFoeData();
    }

    if (this.state.config.maintenanceMode !== '0') {
      return <MaintenancePage />;
    }

    if (this.state.status === 'loading') {
      return <LoadingPlaceholder />;
    }

    if (this.state.status === 'error') {
      return (<>
        <ScrollToTop />
        <Page>
          <PageError hideCTA={true} staticContext={this.state.context} />
        </Page>
      </>);
    }

    return <>
      <ScrollToTop />
      <Page>
        <Switch>
          <Route exact path='/' render={() => <PageHome config={this.state.config} />} />
          <Route exact path='/create/:productSlug' component={PageCreate} />
          <Route exact path='/thanks/:orderHash' component={PageThanks} />
          <Route exact path='/gift/:giftHash' component={PageGift} />
          <Route exact path='/user/:userHash' component={PageUser} />
          <Route exact path='/category/:categorySlug' component={PageCategory} />
          <Route path='/:pageSlug' component={PageStatic} />
          <Route component={PageError} />
        </Switch>
      </Page>
    </>;
  }

}

export default App;
