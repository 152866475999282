import React from 'react';
import propTypes from 'prop-types';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import LoadingPlaceholder from '../LoadingPlaceholder';
import ErrorPanel from '../ErrorPanel';
import Product from '../Product';

class ProductThumbnail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalActive: false,
      redirect: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
  }

  static get propTypes() {
    return {
      status: propTypes.string,
      product: propTypes.object,
      animationOffset: propTypes.number,
      thumbnailLink: propTypes.bool,
      contentEndpoint: propTypes.string,
    };
  }

  toggleModal() {
    this.setState({
      modalActive: !this.state.modalActive,
      redirect: false,
    });
  }

  selectProduct() {
    if (window.FOE?.flags?.bypassProductPreviewModal) {
      this.setState({
        modalActive: false,
        redirect: true,
      });
    } else {
      this.toggleModal();
    }
  }

  render () {
    if (this.props.status === 'loading') {
      return <LoadingPlaceholder />;
    }

    if (this.state.redirect) {
      return <Redirect push to={'/create/' + this.props.product.slug} />;
    }

    let name;
    let description;
    let thumbnailAsset;

    if (this.props.status === 'error') {
      name = 'Product not found';
      description = 'Product not found';
      thumbnailAsset = (
        <ErrorPanel variant="square">
          <p>There was an error loading this product</p>
        </ErrorPanel>
      );
    } else {
      name = this.props.product.name;
      description = this.props.product.description;
      thumbnailAsset = <img
        className="image"
        src={
          this.props.contentEndpoint +
          this.props.product.assets.image +
          '?style=thumbnail'
        }
        title={ name }
        alt={ description }
      />;
    }

    return (<>
      <div
        className={'ProductThumbnail' + (this.props.thumbnailLink ? ' link' : '')}
        style={{ animationDelay: (((this.props.animationOffset || 0) + 1) * 100) + 'ms' }}
        onClick= { this.props.thumbnailLink ? this.selectProduct : null }
      >

        { thumbnailAsset }

        { this.props.thumbnailLink &&
          <button className="btn btn-primary gift-select">select card</button>
        }

      </div>

      { this.props.status === 'okay' && this.props.thumbnailLink &&
        <Product
          mode="modal"
          product={this.props.product}
          modalActive={this.state.modalActive}
          toggleModal={this.toggleModal}
        />
      }
    </>);
  }

}

export default ProductThumbnail;
