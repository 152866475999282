import React from 'react';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Category from './Category';
import PageError from './PageError';
import LoadingPlaceholder from './LoadingPlaceholder';

import { getCategoryBySlug } from '../utilities/api';
import setWindowTitle from '../utilities/set-window-title';

class PageCategory extends React.Component {

  constructor (props) {
    super(props);

    // Pick up and clear server-side provided context
    this.context = props.staticContext;
    if (typeof window !== 'undefined' && window.__INITIAL_CONTEXT__) {
      this.context = window.__INITIAL_CONTEXT__;
      window.__INITIAL_CONTEXT__ = false;
    }

    // Set up basic starting state
    this.state = {
      status: 'loading',
      category: null,
    };

    // If we already have context, populate the state
    if (this.context) {
      if (this.context.data) {
        this.state = {
          status: 'okay',
          category: this.context.data.category,
        };
      } else {
        this.state = {
          status: 'error',
          category: null,
        };
      }
    }

    this._isMounted = false;
  }

  static get propTypes() {
    return {
      match: propTypes.object,
      staticContext: propTypes.object,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.state.category) {
      const slug = this.props.match.params.categorySlug || '';
      getCategoryBySlug(slug)
        .then( (value) => {
          if (!value) {
            throw new Error(`Category with slug "${slug}" not found`);
          }
          this._isMounted && this.setState({
            status: 'okay',
            category: value,
          });
          // Update the window title
          setWindowTitle(value.name);
        })
        .catch( (error) => {
          this._isMounted && this.setState({
            status: 'error',
            category: null,
          });
          console.error(error);
        } );
    }
  }

  render() {
    if (this.state.status === 'loading') {
      return <LoadingPlaceholder />;
    }

    if (this.state.status === 'error') {
      return <PageError staticContext={this.context} />;
    }

    if (this.state.category) {
      return (
        <section className="PageCategory">
          <Category category={this.state.category} />
        </section >
      );
    }

    return (
      <section className="PageCategory">
        <Category categorySlug={this.props.match.params.categorySlug || ''}/>
      </section >
    );
  }

}

export default withRouter(PageCategory);
